import { useState } from 'react';
import { Container, Offcanvas, Button } from 'react-bootstrap';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PersonCircle } from 'react-bootstrap-icons';
import Profile from 'components/profile/profile';
import 'dayjs/locale/en';
import 'dayjs/locale/tr';
import Language from 'components/general/Language';
import { AWS_BUCKET_URL } from '../toolkit/constants';

const Header = () => {
  const userBalance = useSelector((state) => state.auth.balance);
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  console.log(location);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const userMenu = (
    <>
      <Button variant="link text-white col-auto ms-2" onClick={handleShow}>
        <PersonCircle size="20" />
        <div className="fs-8">
          {(userBalance ?? 0).toLocaleString('de-DE', {
            maximumFractionDigits: 2
          })}
        </div>
      </Button>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="p-0 pb-3">
          <Profile onClose={handleClose} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );

  return (
    <header className="header-top" style={{ padding: 0 }}>
      <Container fluid className="h-100">
        <div className="d-flex align-items-center mb-2">
          {location.pathname !== '/slot-game' &&
            location.pathname !== '/casino' &&
            location.pathname !== '/slot' && (
              <NavLink to="/search">
                <img
                  className="text-white fs-8 svg-size-20"
                  style={{
                    cursor: 'pointer',
                    marginTop: '10px',
                    marginLeft: '5px',
                    filter: 'invert(1)'
                  }}
                  src={`${AWS_BUCKET_URL}/Icons/search-icon.svg`}
                />
              </NavLink>
            )}
          {user && (
            <NavLink to="/" className={'mt-3'}>
              <img
                width="185"
                height="45"
                style={{
                  cursor: 'pointer',
                  paddingBottom: '10px'
                }}
                src={`${AWS_BUCKET_URL}/Icons/live90logofullwhite2.png`}
              />
            </NavLink>
          )}
          {((location.pathname === '/casinobase' &&
            (location.search === '?game=vivo' || location.search === '?game=evo')) ||
            location.pathname === '/slot') && (
            <NavLink
              to={{
                pathname: location.pathname === '/slot' ? '/' : '/casinobase',
                search: location.pathname === '/slot' ? '' : 'home'
              }}
              className={'d-block me-2'}
              style={{
                width: 20,
                minWidth: 20
              }}>
              <img
                className="text-white fs-7 w-100 icon-color cursor-pointer"
                src={`${AWS_BUCKET_URL}/Icons/back-icon.svg`}
                width={20}
              />
            </NavLink>
          )}
          <div className="d-flex ms-auto fs-6">
            <Language isDesktop={true} />
          </div>
          {user && userMenu}
        </div>
      </Container>
    </header>
  );
};

export default Header;
