import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import { Combination } from 'js-combinatorics';
import { login, fetchUser } from '../auth';
import { getBetSlipMatchesReq } from './services';
import oddsTransformer from 'toolkit/oddsTransformer';
import { checkIfLiveEvent } from 'toolkit/utils';

const initialState = {
  outright: 0,
  total: 10,
  defaultStake: 10,
  currencyCode: 'EUR',
  bets: [],
  system: false,
  combinations: [],
  maxWinning: 0,
  tax: 0,
  taxRate: 0,
  stakePerBet: 0,
  numberOfBets: 0
};

export const getBetSlipMatches = createAsyncThunk('betSlip/getBetSlipMatches', async () => {
  try {
    const betSlips = localStorage.getItem('betSlipData');
    if (betSlips) {
      const betSlipObject = JSON.parse(betSlips);
      if (betSlipObject.bets?.length === 0) return null;
      const body = betSlipObject.bets.map((b) => ({
        eventId: b.providerId,
        marketId: b.marketStringId,
        outcomeId: b.outcomeId
      }));
      const response = await getBetSlipMatchesReq({
        outcomes: body
      });
      return response.map((m) => {
        return { ...m, markets: m.markets.filter((m) => m.outcomes.length > 0) };
      });
    }
    return null;
  } catch (error) {
    return null;
  }
});

export const checkEventBetStatus_UpdateMsg = (payload) => (dispatch, getState) => {
  const { bets } = getState().betSlip;
  const events = payload.events;
  events.forEach((event) => {
    const filteredBetList = bets.filter((b) => b.providerId === event.gameNumber);
    if (filteredBetList.length > 0) {
      filteredBetList.forEach((bet) => {
        try {
          const filteredMarket = event.markets.find((m) => m.stringId === bet.marketStringId);

          const updatedOutcome = filteredMarket.outcomes.find((o) => o.id === bet.outcomeId);

          if (updatedOutcome) {
            const updatedBetData = {
              providerId: bet.providerId,
              marketStringId: bet.marketStringId,
              outcomeId: bet.outcomeId,
              enabled: updatedOutcome.enabled && !event.placeBetsNotAllowed,
              odds: updatedOutcome.odds,
              match: event
            };

            dispatch(updateBetSlipBetsOdds(updatedBetData));
          }
        } catch (error) {
          // console.log('error', error);
        }
      });
    }
  });
};

export const checkEventBetStatus_DeleteMsg = (payload) => (dispatch, getState) => {
  const { bets } = getState().betSlip;

  const gameNumbers = payload.events;

  gameNumbers.forEach((gameNumber) => {
    bets.forEach((bet) => {
      if (bet.providerId === gameNumber) {
        dispatch(removeBetFromBetSlip(bet));
      }
    });
  });
};

const _oddsTransformer = oddsTransformer();

const calculateMaxWinningAndPrepareBetSlip = (state) => {
  saveBetSlipToLocalStorage(state);
  const outcomeMaps = {};
  state.bets
    .filter((b) => b.enabled)
    .forEach((b) => {
      if (outcomeMaps[b.providerId] === undefined) outcomeMaps[b.providerId] = [];
      outcomeMaps[b.providerId].push({
        providerId: b.providerId,
        marketStringId: b.marketStringId,
        outcomeId: b.outcomeId,
        referenceOdds: b.odds,
        odds: _oddsTransformer.transform(
          new Date(),
          checkIfLiveEvent(b.match),
          false,
          b.marketId,
          new Date(b.match.gameDate),
          b.odds * state.customOddsMultiplier
        )
      });
    });

  const mCount = _.uniqBy(
    state.bets.filter((b) => b.enabled && !b.banker),
    'providerId'
  ).length;

  if (state.system) {
    let allLegs = [];

    //before banker
    const uniqueBetsExceptBanker = _.uniqBy(
      state.bets.filter((b) => b.enabled && !b.banker),
      'providerId'
    );
    state.combinations.forEach((c) => {
      const legsObj = Combination.of(
        uniqueBetsExceptBanker.map((b) => b.providerId),
        c
      );
      allLegs = allLegs.concat([...legsObj]);
    });

    //add bankers
    const uniqueBankerBets = _.uniqBy(
      state.bets.filter((b) => b.enabled && b.banker),
      'providerId'
    );
    allLegs = allLegs.map((l) => [...l, ...uniqueBankerBets.map((b) => b.providerId)]);

    //populateWithOutcomes
    Object.keys(outcomeMaps).map((providerId) => {
      const tempLegs = [];
      allLegs
        .filter((l) => l.includes(providerId))
        .map((l) => {
          outcomeMaps[providerId].map((o) => {
            tempLegs.push([...l.filter((ll) => ll !== providerId), o]);
          });
        });

      allLegs
        .filter((l) => l.includes(providerId) === false)
        .map((l) => {
          tempLegs.push(l);
        });

      allLegs = tempLegs;
    });

    const tax = (state.total * (state.taxRate / 100)).toFixed(2);
    const stake = state.total - tax;
    const stakePerBet = (stake / allLegs.length).toFixed(2);

    const maxWinningLegs = allLegs.filter((l) =>
      l.every(
        (outcome) => _.max(outcomeMaps[outcome.providerId].map((o) => o.odds)) === outcome.odds
      )
    );
    const winnings = maxWinningLegs.map((s) =>
      s.reduce((p, c) => p * c.odds, stake / allLegs.length).toFixed(2)
    );
    const maxWinning = winnings.reduce((p, c) => p + _.round(c, 2), 0).toFixed(2);

    return {
      tax: allLegs.length === 0 ? 0 : tax,
      stakePerBet: allLegs.length === 0 ? 0 : stakePerBet,
      totalOdds: null,
      maxWinning: maxWinning,
      numberOfBets: allLegs.length
    };
  } else if (state.bets.filter((b) => b.enabled).length === mCount) {
    const nBets = Object.keys(outcomeMaps).reduce((p, c) => outcomeMaps[c].length * p, 1);
    const tax = (state.total * (state.taxRate / 100)).toFixed(2);
    const stake = state.total - tax;
    const winning = state.bets
      .filter((b) => b.enabled)
      .reduce(
        (p, c) =>
          p *
          _oddsTransformer.transform(
            new Date(),
            checkIfLiveEvent(c.match),
            false,
            c.marketId,
            new Date(c.match.gameDate),
            c.odds * state.customOddsMultiplier
          ),
        _.round(stake, 2)
      )
      .toFixed(2);
    const totalOdds = (winning / _.round(stake, 2)).toFixed(2);
    return {
      tax: tax,
      stakePerBet: stake / nBets,
      totalOdds,
      maxWinning: winning,
      numberOfBets: 1
    };
  } else {
    const nBets = Object.keys(outcomeMaps).reduce((p, c) => outcomeMaps[c].length * p, 1);
    const tax = (state.total * (state.taxRate / 100)).toFixed(2);
    const sPerBet = (state.total - tax) / nBets;

    let bets = [state.bets.filter((b) => b.enabled).map((p) => p.providerId)];
    Object.keys(outcomeMaps).map((providerId) => {
      const tempLegs = [];
      bets
        .filter((l) => l.includes(providerId))
        .map((l) => {
          outcomeMaps[providerId].map((o) => {
            tempLegs.push([...l.filter((ll) => ll !== providerId), o]);
          });
        });

      bets
        .filter((l) => l.includes(providerId) === false)
        .map((l) => {
          tempLegs.push(l);
        });

      bets = tempLegs;
    });

    const marketMaps = {};
    Object.values(outcomeMaps).forEach((b) =>
      b.forEach((o) => {
        if (marketMaps[o.providerId + '<>' + o.marketStringId] === undefined) {
          marketMaps[o.providerId + '<>' + o.marketStringId] = [];
        }
        marketMaps[o.providerId + '<>' + o.marketStringId].push(o);
      })
    );
    const maxWinningLegs = bets.filter((l) =>
      l.every(
        (outcome) =>
          _.max(
            marketMaps[outcome.providerId + '<>' + outcome.marketStringId].map((o) => o.odds)
          ) === outcome.odds
      )
    );
    // const winnings = maxWinningLegs.map((s) =>
    //   s
    //     .reduce(
    //       (p, c) =>
    //         p *
    //         _oddsTransformer.transform(
    //           new Date(),
    //           checkIfLiveEvent(c.match),
    //           false,
    //           c.marketId,
    //           new Date(c.match.gameDate),
    //           c.odds * state.customOddsMultiplier
    //         ),
    //       sPerBet
    //     )
    //     .toFixed(2)
    // );

    const winnings = maxWinningLegs.map((s) => s.reduce((p, c) => p * c.odds, sPerBet).toFixed(2));

    const maxWinning = winnings.reduce((p, c) => p + _.round(c, 2), 0).toFixed(2);

    return {
      tax: tax,
      stakePerBet: sPerBet.toFixed(2),
      totalOdds: null,
      maxWinning: maxWinning,
      numberOfBets: nBets
    };
  }
};

const saveBetSlipToLocalStorage = (state) => {
  localStorage.setItem('betSlipData', JSON.stringify(state));
};

export const betSlipSlice = createSlice({
  name: 'betSlip',
  initialState,
  reducers: {
    saveBetSlip: (state) => {
      saveBetSlipToLocalStorage(state);
    },
    loadBetSlip: (state, action) => {
      const updatedState = { ...state, ...action.payload };
      return {
        ...updatedState,
        ...calculateMaxWinningAndPrepareBetSlip(updatedState)
      };
    },
    addBetToBetSlip: (state, action) => {
      const newState = {
        ...state,
        bets: [
          ...state.bets,
          {
            ...action.payload,
            enabled: true,
            banker: false
          }
        ]
      };
      return { ...newState, ...calculateMaxWinningAndPrepareBetSlip(newState) };
    },
    removeBetFromBetSlip: (state, action) => {
      const { providerId, marketStringId, outcomeId } = action.payload;
      const newState = {
        ...state,
        bets: state.bets.filter(
          (b) =>
            b.providerId !== providerId ||
            b.marketStringId !== marketStringId ||
            b.outcomeId !== outcomeId
        )
      };
      return { ...newState, ...calculateMaxWinningAndPrepareBetSlip(newState) };
    },
    setBetSlipEnabled: (state, action) => {
      const { providerId, enabled } = action.payload;
      const bets = state.bets.map((b) => {
        return { ...b, enabled: providerId === b.providerId ? enabled : b.enabled };
      });
      const combinationRelatedBetCount = _.uniqBy(
        bets.filter((b) => b.enabled && !b.banker),
        'providerId'
      ).length;
      const newState = {
        ...state,
        bets: bets,
        combinations: [...state.combinations.filter((i) => i <= combinationRelatedBetCount)]
      };
      return { ...newState, ...calculateMaxWinningAndPrepareBetSlip(newState) };
    },
    setBetSlipBanker: (state, action) => {
      const { providerId, banker } = action.payload;
      const bets = state.bets.map((b) => ({
        ...b,
        banker: providerId === b.providerId ? banker : b.banker
      }));
      const combinationRelatedBetCount = _.uniqBy(
        bets.filter((b) => b.enabled && !b.banker),
        'providerId'
      ).length;
      const newState = {
        ...state,
        bets: bets,
        combinations: [...state.combinations.filter((i) => i <= combinationRelatedBetCount)]
      };
      return { ...newState, ...calculateMaxWinningAndPrepareBetSlip(newState) };
    },
    setBetSlipCombination: (state, action) => {
      const combination = action.payload;
      const include = state.combinations.includes(combination);
      const newState = {
        ...state,
        combinations: include
          ? state.combinations.filter((c) => c !== combination)
          : [...state.combinations, combination].sort()
      };
      return { ...newState, ...calculateMaxWinningAndPrepareBetSlip(newState) };
    },
    setCustomOddsMultiplier: (state, action) => {
      if (action.payload >= 0 && action.payload <= 2) {
        state.customOddsMultiplier = action.payload;
        return state;
      }
      console.assert('invalid custom Odds multiplier ', action.payload);
    },
    removeAllBetSlip: (state) => {
      localStorage.removeItem('betSlipData');
      return {
        ...state,
        outright: 0,
        total: state.defaultStake,
        currencyCode: 'EUR',
        bets: [],
        system: false,
        combinations: [],
        maxWinning: 0
      };
    },
    setTaxRate: (state, action) => {
      state.taxRate = action.payload;
    },
    setDefaultStake: (state, action) => {
      state.defaultStake = action.payload;
    },
    setTotal: (state, action) => {
      const newState = { ...state, total: action.payload };
      return { ...newState, ...calculateMaxWinningAndPrepareBetSlip(newState) };
    },
    setBetSlipSystem: (state, action) => {
      const value = action.payload;
      const newState = {
        ...state,
        system: value,
        combinations: value ? state.combinations : []
      };
      return { ...newState, ...calculateMaxWinningAndPrepareBetSlip(newState) };
    },
    updateBetSlipBetsOdds: (state, action) => {
      const { providerId, marketStringId, outcomeId, enabled, odds, match } = action.payload;
      const bets = state.bets.map((b) => {
        if (
          b.providerId === providerId &&
          b.marketStringId === marketStringId &&
          b.outcomeId === outcomeId
        ) {
          return { ...b, enabled, odds, match };
        }
        return b;
      });
      const newState = {
        ...state,
        bets: bets
      };
      return { ...newState, ...calculateMaxWinningAndPrepareBetSlip(newState) };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        const { customer } = action.payload;
        state.taxRate = customer.customerLimitDto.taxRate ?? 0;
        const defaultStake = customer.customerSettingsDto.defaultStake || 10;
        state.total = defaultStake;
        state.defaultStake = defaultStake;
        state = {
          ...state,
          ...calculateMaxWinningAndPrepareBetSlip(state)
        };
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        if (action.payload) {
          const { loggedCustomer } = action.payload;
          state.taxRate = loggedCustomer.customer.customerLimitDto.taxRate ?? 0;
          const defaultStake = loggedCustomer.customer.customerSettingsDto.defaultStake || 10;
          state.total = defaultStake;
          state.defaultStake = defaultStake;
          state = {
            ...state,
            ...calculateMaxWinningAndPrepareBetSlip(state)
          };
        }
      })
      .addCase(getBetSlipMatches.fulfilled, (state, action) => {
        const events = action.payload;
        if (!events || events.length === 0) return;
        const betSlips = localStorage.getItem('betSlipData');
        const betSlipObject = JSON.parse(betSlips);
        betSlipObject.bets = betSlipObject.bets.filter((p) => {
          if (events.some((m) => m.gameNumber === p.providerId)) {
            p.odds = events
              .find((m) => m.gameNumber === p.providerId)
              .markets.find((m) => m.stringId === p.marketStringId)
              .outcomes.find((o) => o.id === p.outcomeId).odds;
            return true;
          } else {
            return false;
          }
        });
        let newState = {
          ...state,
          ...betSlipObject
        };
        newState = {
          ...newState,
          ...calculateMaxWinningAndPrepareBetSlip(newState)
        };
        Object.keys(newState).forEach((k) => {
          state[k] = newState[k];
        });
      });
  }
});

export const {
  saveBetSlip,
  loadBetSlip,
  addBetToBetSlip,
  removeBetFromBetSlip,
  setBetSlipEnabled,
  setBetSlipBanker,
  setBetSlipCombination,
  setCustomOddsMultiplier,
  removeAllBetSlip,
  setTaxRate,
  setDefaultStake,
  setTotal,
  setBetSlipSystem,
  updateBetSlipBetsOdds
} = betSlipSlice.actions;

export default betSlipSlice.reducer;
