import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row, Modal, Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { BetSlipRow } from './bet_slip_row';
import { Check2Square } from 'react-bootstrap-icons';
import BetModal from '../general/bet_modal';
import Numpad from './numpad';
import * as _ from 'lodash';
import { combination } from 'js-combinatorics';
//import './betslip.scss';
import './forza-betslip.scss';
import FetchButton from 'components/general/FetchButton';
import { AWS_BUCKET_URL } from '../../toolkit/constants';
import useInterval from 'toolkit/useHooks/useInterval';
import { ConvertDisplayNumber } from '../../toolkit/NumberUtil';
import { currencyFormatter, parsePlaceBetResponseMsg } from 'toolkit/utils';
import { EnumBetSlipType } from '../../toolkit/Enums';
import { getShopId } from 'stores/auth/services';
import { fetchBalance, updateBalance } from 'stores/auth';
import {
  removeAllBetSlip,
  setBetSlipCombination,
  setBetSlipSystem,
  setTotal
} from 'stores/betSlip';
import { postPlaceBetReq } from 'stores/betSlip/services';
import useOddTransformer from '../../toolkit/useHooks/useOddsTransformer.js';
import { checkIfLiveEvent } from '../../toolkit/utils';
import { useCategoryLimitValidation } from 'toolkit/useHooks/useCategoryLimitValidation';

export default function BetSlipList(props) {
  const dispatch = useDispatch();
  const { user, balance, refundBonusBalance, userLimits } = useSelector((state) => state.auth);
  const betSlip = useSelector((state) => state.betSlip);
  const betSlipEvents = useSelector(
    (state) => {
      const eventsObj = {};
      betSlip.bets.forEach((bet) => {
        const event = state.match.events[bet.providerId];
        if (event) {
          eventsObj[bet.providerId] = event;
        }
      });
      return eventsObj;
    },
    (prev, next) => _.isEqual(prev, next)
  );

  const { t } = useTranslation('translations', { useSuspense: false });
  const navigate = useNavigate();
  const [activeSegment, setActiveSegment] = useState(betSlip.system ? 2 : 1);
  const [combinationRelatedBetCount, setCombinationRelatedBetCount] = useState(0);
  const [stakeExpanded, setStakeExpanded] = useState(false);
  const [numpadShow, setNumpadShow] = useState(false);
  const [combinationsExpanded, setCombinationsExpanded] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [combinationsText, setCombinationsText] = useState([]);
  const [responseData, setResponseData] = useState({
    success: false,
    text: ''
  });
  const [numberOfBets, setNumberOfBets] = useState(0);
  const [stakePerBet, setStakePerBet] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [maxProfit, setMaxProfit] = useState(0);
  const stakeInput = useRef(null);
  const betSlipTop = useRef(null);
  const betSlipCenter = useRef(null);
  const betSlipBottom = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showConfirmationRow, setShowConfirmationRow] = useState(false);
  const [timer, setTimer] = useState(6);
  const _currencyFormatter = currencyFormatter(betSlip.currencyCode, 2);
  const oddsTransformer = useOddTransformer();
  const { validateBetSlipCategoryLimit } = useCategoryLimitValidation();
  const [useBonusBalance, setUseBonusBalance] = useState(
    localStorage.getItem('useBonusBalance') === 'true' && refundBonusBalance > 0 ? true : false
  );
  const isSingleBet = betSlip.bets.filter((b) => b.enabled).length === 1;

  const handleRestoreClick = () => {
    setShowSuccessModal(false);
    setTimer(6);
    setShowConfirmationRow(false);
  };

  useInterval(
    () => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    },
    showSuccessModal ? 1000 : null
  );

  useEffect(() => {
    if (timer === 0) {
      modalCallback(true);
    }
  }, [timer]);

  const goToPreviousPath = () => {
    navigate(-1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(fetchBalance());
    }
  }, []);

  useEffect(() => {
    if (betSlip.bets.length === 0) {
      goToPreviousPath();
      return;
    }

    const mCount = _.uniqBy(
      betSlip.bets.filter((b) => b.enabled && !b.banker),
      'providerId'
    ).length;
    setCombinationRelatedBetCount(mCount);

    if (betSlip.system) {
      setCombinationsText(betSlip.combinations.map((c) => ' ' + c + '/' + mCount));
    }
    setNumberOfBets(betSlip.numberOfBets);
    setStakePerBet(betSlip.stakePerBet);
    setTaxAmount(betSlip.tax);
    setMaxProfit(betSlip.maxWinning);
  }, [betSlip]);

  function combinationPress(combination) {
    dispatch(setBetSlipCombination(combination));
  }

  useEffect(() => {
    dispatch(setBetSlipSystem(activeSegment === 2));
  }, [activeSegment]);

  // useEffect(() => {
  //   fetchUser();
  // }, []);

  const deleteAllBets = () => {
    //goToPreviousPath();
    dispatch(removeAllBetSlip());
    // goToPreviousPath();
  };

  const modalCallback = (state) => {
    setShowSuccessModal(false);
    if (state) {
      deleteAllBets();
    }
    setModalShow(false);
  };

  function changeBetSlipTotal(value) {
    if (
      value > 0 &&
      betSlip.total !== value &&
      value <= 4000 &&
      (useBonusBalance ? value <= refundBonusBalance : value <= balance)
    ) {
      dispatch(setTotal(value));
    }
    if (numpadShow) {
      setNumpadShow(false);
    }
    console.log('betSlip: ', betSlip);
    const categoryLimitsValidation = validateBetSlipCategoryLimit(betSlipEvents, value);
    if (!categoryLimitsValidation.valid) {
      setResponseData({ success: false, text: categoryLimitsValidation.text });
      setShowSuccessModal(true);
      return;
    }
  }

  function checkNumpad() {
    //We want to close the confirmation row if the user changes the stake for accidental confirmation
    setShowConfirmationRow(false);

    stakeInput.current.blur();
    setNumpadShow(true);
  }

  async function placeBet() {
    const customerBalance = await dispatch(fetchBalance());
    if ((!useBonusBalance && customerBalance.payload.balance <= 0) || customerBalance.error) return;

    if (!user) {
      setResponseData({ success: false, text: t('pleaseLogin') });
      setShowSuccessModal(true);
      return;
    }

    const categoryLimitsValidation = validateBetSlipCategoryLimit(betSlipEvents, betSlip.total);
    if (!categoryLimitsValidation.valid) {
      setResponseData({ success: false, text: categoryLimitsValidation.text });
      setShowSuccessModal(true);
      return;
    }

    if (betSlip.system === false && betSlip.bets.length > 15) {
      setResponseData({ success: false, text: t('numberofmatch') });
      setShowSuccessModal(true);
      return;
    }

    if (betSlip.system && betSlip.bets.length > 12) {
      setResponseData({ success: false, text: t('systemnumberofmatch') });
      setShowSuccessModal(true);
      return;
    }

    try {
      setIsSubmitting(true);
      const totalStake = betSlip.total;
      const combinations = betSlip.combinations;
      let outcomes = betSlip.bets
        .filter((b) => b.enabled)
        .map((b) => {
          const markets = b.match.markets.filter((m) => m.stringId === b.marketStringId);
          let outcomes =
            markets.length > 0 ? markets[0].outcomes.filter((o) => o.id === b.outcomeId) : [];
          const odds =
            outcomes.length > 0
              ? oddsTransformer.transformWithDisplayFormatted(
                  checkIfLiveEvent(b.match),
                  b.match?.isSeason,
                  b?.marketId,
                  new Date(b.match.gameDate),
                  b.odds
                )
              : -1;

          return {
            eventId: b.providerId,
            marketId: b.marketStringId,
            outcomeId: b.outcomeId,
            odds: odds !== -1 ? odds : b.odds,
            referenceOdds: b.odds,
            banker: b.banker
          };
        });

      // check outcomes odds or reference odds equal 1 or 0 or null and refresh page
      if (outcomes.some((o) => o.odds <= 1 || o.odds === 0 || o.odds === null)) {
        window.location.reload();
      }

      const betSlipType = betSlip.system
        ? EnumBetSlipType.SYSTEM
        : outcomes.length === 1
          ? EnumBetSlipType.SINGLE
          : EnumBetSlipType.MULTIPLE;

      let betRequest = {
        betSlipType: betSlipType,
        totalStake: totalStake,
        isAutomaticCashOutEnabled: false,
        automaticCashOutExecutionThreshold: 500,
        combinations: combinations,
        outcomes: outcomes,
        shopId: getShopId(),
        totalOdds: stakePerBet,
        maxWinning: maxProfit,
        accountType: useBonusBalance ? 5100 : 5000
      };

      if (useBonusBalance) {
        localStorage.setItem('useBonusBalance', 'true');
      } else {
        localStorage.removeItem('useBonusBalance');
      }

      const response = await postPlaceBetReq(betRequest);

      const eventGetterFunc = (eventId) => {
        return betSlip.bets.find((b) => b.providerId === eventId);
      };

      if (response.data.success === false) {
        if (response.data.messages[0]?.text.includes('400')) {
          response.data.messages[0].text = t('notenoughbalance');
        } else if (response.data.messages[0]?.code == 'O001') {
          response.data.messages[0].text = t('maxbetslip');
        } else {
          response.data.messages[0].text = parsePlaceBetResponseMsg(
            response.data.messages[0],
            t,
            _currencyFormatter,
            eventGetterFunc
          );
        }
      }

      setResponseData({
        success: response.data.success,
        text: response.data.messages[0]?.text
      });
      dispatch(updateBalance(totalStake));
      //setModalShow(true);
      setShowSuccessModal(true);
      dispatch(fetchBalance());
    } catch (error) {
      console.log(error);
      setResponseData({ success: false, text: error.message });
      setShowSuccessModal(true);
    }
    setIsSubmitting(false);
  }

  const calculateBetSlipRowContainerHeight = () => {
    if (betSlipTop && betSlipTop.current && betSlipBottom && betSlipBottom.current) {
      const betSlipTopHeight = betSlipTop.current.offsetHeight;
      const betSlipBottomHeight = betSlipBottom.current.offsetHeight;
      const headerHeight = 56;
      if (betSlipCenter && betSlipCenter.current) {
        betSlipCenter.current.style.setProperty(
          '--betSlipCenterHeight',
          `calc(100vh - ${betSlipTopHeight + betSlipBottomHeight + headerHeight + 90}px)`
        );
        betSlipCenter.current.style.setProperty(
          '--betSlipCenterTopOffset',
          `${headerHeight + betSlipTopHeight}px`
        );
        betSlipCenter.current.classList.add('calculated-height');
      }
    }
  };

  useEffect(() => {
    calculateBetSlipRowContainerHeight();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      calculateBetSlipRowContainerHeight();
      if (betSlipCenter && betSlipCenter.current) {
        betSlipCenter.current.scrollTop = betSlipCenter.current.scrollHeight;
      }
    }, 50);
  }, [stakeExpanded, combinationsExpanded, activeSegment]);

  return (
    <div className={props.type == 'desktop' ? 'card-body' : 'bet-slip-container'}>
      <div
        className={props.type == 'desktop' ? 'container-fluid' : 'bet-slip-top container-fluid'}
        ref={betSlipTop}>
        <Row className="align-items-center flex-nowrap">
          {props.type !== 'desktop' ? (
            <div className="col-auto">
              <div className="top-header-right" style={{ cursor: 'pointer' }}>
                <button
                  className="btn p-0"
                  disabled={isSubmitting}
                  onClick={() => goToPreviousPath()}>
                  <img src={`${AWS_BUCKET_URL}/Icons/117.png`} alt={'close'} />
                </button>
              </div>
            </div>
          ) : null}
          <Col>
            <a
              onClick={() => setActiveSegment(1)}
              className={
                'bet-slip-segment-button' +
                (activeSegment == 1 ? `${' segment-active'}` : '') +
                ' ' +
                `${isSingleBet ? '' : 'px-2 fs-9'}`
              }>
              {isSingleBet
                ? t('single')
                : `${t('combination')}(${betSlip.bets.filter((b) => b.enabled).length})`}
            </a>
            <a
              onClick={() => {
                setActiveSegment(2);
                setCombinationsExpanded(true);
              }}
              className={
                'bet-slip-segment-button' +
                (activeSegment == 2 ? `${' segment-active'}` : '') +
                ' ' +
                `${isSingleBet ? '' : 'pe-2 fs-9'}`
              }>
              {isSingleBet
                ? t('system')
                : `${t('system')}(${betSlip.bets.filter((b) => b.enabled).length})`}
            </a>
          </Col>
          {user && props.type != 'desktop' ? (
            refundBonusBalance > 0 && balance < userLimits.minimumStakeLimit ? (
              <div className="col-auto fw-bold d-flex flex-column">
                <div className="d-flex justify-content-between align-items-center gap-2">
                  <span>{t('balance')}:</span>
                  <span className="fs-9">
                    {balance.toLocaleString('de-DE', {
                      minimumFractionDigits: 2
                    }) + ' EUR'}
                  </span>
                </div>
                <div className="d-flex justify-content-between align-items-center gap-2">
                  <span>{t('bonus')}:</span>
                  <span className="fs-9">
                    {refundBonusBalance.toLocaleString('de-DE', {
                      minimumFractionDigits: 2
                    }) + ' EUR'}
                  </span>
                </div>
              </div>
            ) : (
              <div className="col-auto fw-bold d-flex gap-2">
                <div>
                  <span>{t('balance')}:</span>
                  <br />
                  <span className="fs-9">
                    {balance.toLocaleString('de-DE', {
                      minimumFractionDigits: 2
                    }) + ' EUR'}
                  </span>
                </div>
              </div>
            )
          ) : null}
        </Row>
      </div>
      <div className={props.type == 'desktop' ? '' : 'bet-slip-center'} ref={betSlipCenter}>
        {_.uniqBy(betSlip.bets, 'providerId').map((bet) => {
          return <BetSlipRow key={bet.providerId + bet.marketStringId + bet.outcomeId} bet={bet} />;
        })}
        <div>
          <a className="text-danger text-decoration-underline" onClick={() => deleteAllBets()}>
            {t('deleteAllBets')}
          </a>
        </div>
      </div>
      {numpadShow && (
        <Numpad
          onSuccess={changeBetSlipTotal}
          initialValue={betSlip.total && betSlip.total.toFixed(2)}
          unit={betSlip.currencyCode}
        />
      )}
      <Row
        ref={betSlipBottom}
        className={
          props.type == 'desktop'
            ? stakeExpanded
              ? 'bet-slip stakeExpanded-height'
              : 'bet-slip'
            : stakeExpanded
              ? 'bet-slip-bottom stakeExpanded-height'
              : 'bet-slip-bottom'
        }>
        {betSlip.system && betSlip.bets.length > 0 && (
          <div className="col-12 bg-white">
            <div className="d-flex justify-content-between align-items-center bg-white">
              <div className="">
                <span>{t('combinations') + ' ' + combinationsText}</span>
              </div>
              <div className="">
                <a onClick={() => setCombinationsExpanded(!combinationsExpanded)}>
                  <img
                    height={30}
                    width={30}
                    style={{
                      transform: `${!combinationsExpanded ? 'rotate(-90deg)' : 'rotate(90deg)'}`,
                      color: 'rgba(0, 0, 0, 0.7)'
                    }}
                    src={`${AWS_BUCKET_URL}/Icons/114.png`}
                    alt={'expand combination select area'}
                  />
                </a>
              </div>
            </div>
            {combinationsExpanded && (
              <div className="combination-scrollView vstack gap-1 text-start w-100">
                {_.uniqBy(betSlip.bets, 'providerId')
                  .filter((bet) => bet.enabled && !bet.banker)
                  .map((bet, index) => {
                    return (
                      <div key={index}>
                        <a
                          onClick={() => {
                            combinationPress(index + 1);
                          }}
                          className={
                            betSlip.combinations.includes(index + 1) && 'text-success fw-bold'
                          }>
                          <Check2Square
                            fill={betSlip.combinations.includes(index + 1) ? 'green' : 'grey'}
                            size={22}></Check2Square>
                          &nbsp;
                          {index + 1} {'from'} {combinationRelatedBetCount} {'='}
                          {combination(combinationRelatedBetCount, index + 1)} {'Betting'}
                        </a>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        )}
        <div className="d-flex justify-content-between mt-1 mb-2">
          <div className="d-flex align-items-center">
            <span className="text-white fw-bold fs-15 me-2">
              {t('pages.bettingHistoryDetail.details.stake')}
            </span>
            <button className="btn p-0" onClick={() => setStakeExpanded(!stakeExpanded)}>
              <img
                height={30}
                width={30}
                className="icon-color"
                style={{
                  transform: `${!stakeExpanded ? 'rotate(-90deg)' : 'rotate(90deg)'}`,
                  color: 'rgba(0, 0, 0, 0.7)'
                }}
                src={`${AWS_BUCKET_URL}/Icons/114.png`}
                alt={'expand stake area'}
              />
            </button>
          </div>
          <div className="d-flex align-items-center">
            <span className="ms-auto fs-14 text-white me-2">Eur</span>
            <div className="me-1">
              <input
                inputMode="none"
                ref={stakeInput}
                onClick={() => checkNumpad()}
                className="form-control form-control-sm bg-white"
                value={betSlip.total && ConvertDisplayNumber(betSlip.total)}
                size={betSlip.total && betSlip.total.toFixed(2).replace('.', ',').length}
                readOnly
              />
            </div>
            <button
              onClick={() => changeBetSlipTotal(betSlip.total - 1)}
              className="btn btn-light btn-sm px-75 me-1">
              -
            </button>
            <button
              onClick={() => changeBetSlipTotal(betSlip.total + 1)}
              className="btn btn-light btn-sm px-75">
              +
            </button>
          </div>
        </div>
        {stakeExpanded ? (
          <div>
            <Row>
              <Col>
                <div className="float-left" style={{ color: 'white', fontSize: '14x' }}>
                  {t('pages.bettingHistoryDetail.details.numberOfBets')}
                </div>
              </Col>
              <Col>
                <div
                  className="float-right"
                  style={{
                    color: 'white',
                    fontSize: '14px',
                    fontWeight: '800'
                  }}>
                  {numberOfBets}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="float-left" style={{ color: 'white', fontSize: '14x' }}>
                  {t('pages.bettingHistoryDetail.details.tax')}
                </div>
              </Col>
              <Col>
                <div
                  className="float-right"
                  style={{
                    color: 'white',
                    fontSize: '14px',
                    fontWeight: '400'
                  }}>
                  {`(${ConvertDisplayNumber(taxAmount)} EUR)`}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="float-left" style={{ color: 'white', fontSize: '14x' }}>
                  {t('pages.bettingHistoryDetail.details.stakePerBet')}
                </div>
              </Col>
              <Col>
                <div
                  className="float-right"
                  style={{
                    color: 'white',
                    fontSize: '14px',
                    fontWeight: '400'
                  }}>
                  {`(${ConvertDisplayNumber(stakePerBet)} EUR)`}
                </div>
              </Col>
            </Row>
          </div>
        ) : null}
        <Container>
          <Row>
            <Col>
              <div
                className="float-left"
                style={{
                  color: 'white',
                  fontSize: '15px',
                  paddingBottom: '4px'
                }}>
                {t('pages.bettingHistoryDetail.details.maxProfit')}
              </div>
            </Col>
            <Col className="float-right">
              <div
                className="float-right"
                style={{ color: 'yellow', fontSize: '16px', fontWeight: '700' }}>
                {`(${ConvertDisplayNumber(maxProfit)} EUR)`}
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <div className="d-flex justify-content-center gap-3">
            {showConfirmationRow ? (
              <>
                <Button
                  className="btn btn-danger flex-grow-1"
                  type="button"
                  onClick={() => setShowConfirmationRow(false)}>
                  {t('pages.betslip.cancel')}
                </Button>
                <FetchButton
                  variant="success"
                  className="flex-grow-1 w-25"
                  type="button"
                  onClick={placeBet}
                  fetching={isSubmitting}
                  disabled={modalShow}>
                  {t('pages.betslip.confirm')}
                </FetchButton>
              </>
            ) : (
              <div className="w-100">
                {refundBonusBalance > 0 && balance < userLimits.minimumStakeLimit && (
                  <Form.Switch
                    variant="success"
                    type="switch"
                    id="custom-switch"
                    label={t('pages.betslip.useBonusBalance')}
                    className="text-white text-start"
                    onChange={(e) => setUseBonusBalance(e.target.checked)}
                    checked={useBonusBalance}
                    isValid
                  />
                )}
                <Button
                  variant="success"
                  className="placeBet-button text-center pointer w-100"
                  type="button"
                  onClick={() => setShowConfirmationRow(true)}>
                  {t('pages.betslip.placeBet')}
                </Button>
              </div>
            )}
          </div>
        </Container>
      </Row>
      <Modal show={isSubmitting} onHide={() => setIsSubmitting(true)} />
      {responseData.success === false ? (
        <BetModal
          onModalClose={modalCallback}
          responseText={responseData.text}
          successState={responseData.success}
          onModalActive={showSuccessModal}
        />
      ) : (
        <Modal show={showSuccessModal} centered backdrop="static" keyboard={false}>
          <Modal.Body className="p-4 text-center">
            <h5 className="mb-0">{t('pages.betslip.successfulPlaceBet')}</h5>
            <p className="mb-0">{t('pages.betslip.successfulPlaceBetText')}</p>
          </Modal.Body>
          <Modal.Footer className="d-flex gap-3 flex-nowrap">
            <Button
              variant="danger"
              size="lg"
              className="fs-6 w-100"
              onClick={() => modalCallback(true)}>
              {t('pages.betslip.buttonModalClose', [timer])}
            </Button>
            <Button
              variant="success"
              size="lg"
              className="fs-6 fw-bold w-100"
              onClick={handleRestoreClick}>
              {t('pages.betslip.buttonModalRestoreBets')}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}
