import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBalance, logout } from 'stores/auth';
import { AWS_BUCKET_URL } from '../../toolkit/constants';

export default function Profile(props) {
  const dispatch = useDispatch();
  const balance = useSelector((state) => state.auth.balance);
  const user = useSelector((state) => state.auth.user);
  const { t } = useTranslation('translations');
  const { title, onClose } = props;
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchBalance());
  }, []);

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
    onClose();
  };

  return (
    <Container fluid className="profile">
      <Card className="mb-3">
        <Card.Body>
          <Row className="align-items-center">
            <Col xs={7} className="d-flex align-items-center">
              <div className="me-3">
                <img size={24} src={`${AWS_BUCKET_URL}/Icons/58.png`} />
              </div>
              {user && (
                <div className="">
                  <h1 className="display-6 fs-14 fw-bold mb-1">{user.username}</h1>
                  <h1 className="display-6 fs-14 text-muted mb-0">{user.email}</h1>
                </div>
              )}
            </Col>
            <Col xs={5} className="text-end">
              <div className={`badge h5 text-end bg-${balance > 0 ? 'success' : 'danger'}`}>
                {balance.toLocaleString('de-DE', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }) + ' €'}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row>
        <div className="top-header-left">
          <h5>{title}</h5>
        </div>
      </Row>
      <hr className="mt5"></hr>
      <Row id="settings">
        <Col xs={1} className="">
          <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/61.png`} />
        </Col>
        <Col xs={8} className="float-left">
          <Link to={'/profilesettings'} onClick={onClose}>
            {t('settings')}
          </Link>
        </Col>

        <Col xs={3} className="item3">
          {' '}
          <Link to={'/profilesettings'} className="float-right light-grey-text" onClick={onClose}>
            <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
          </Link>
        </Col>
      </Row>
      <hr className="mt5"></hr>
      <Row id="changePassword">
        <Col xs={1} className="">
          <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/62.png`} />
        </Col>
        <Col xs={8} className="float-left">
          <Link to={'/changepassword'} onClick={onClose}>
            {t('changepassword')}
          </Link>
        </Col>

        <Col xs={3} className="item3">
          <Link to={'/changepassword'} className="float-right light-grey-text" onClick={onClose}>
            <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
          </Link>
        </Col>
      </Row>
      <hr className="mt5"></hr>
      <Row id="bettingHistory" key="bettingHistory">
        <Col xs={1} className="">
          <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/67.png`} />
        </Col>
        <Col xs={8} className="float-left">
          <Link to={'/bettinghistory'} onClick={onClose}>
            {t('bettinghistory')}
          </Link>
        </Col>

        <Col xs={3} className="item3">
          {' '}
          <Link to={'/bettinghistory'} className="float-right light-grey-text" onClick={onClose}>
            <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
          </Link>
        </Col>
      </Row>
      <hr className="mt5"></hr>
      {user && (
        <Row id="myFinances" key="myFinances">
          <Col xs={1} className="">
            <img width="25" height="25" src={`${AWS_BUCKET_URL}/Icons/68.png`} />
          </Col>
          <Col xs={8} className="float-left">
            <Link
              to={{
                pathname: `/transactions/${user?.id}`
              }}
              onClick={onClose}>
              {t('transaction')}
            </Link>
          </Col>
          <Col xs={3} className="item3">
            {' '}
            <Link
              to={{
                pathname: `/transactions/${user?.id}`
              }}
              className="float-right light-grey-text"
              onClick={onClose}>
              <img width="20" height="20" src={`${AWS_BUCKET_URL}/Icons/nexticon-caret.svg`} />
            </Link>
          </Col>
        </Row>
      )}
      <hr className="mt5"></hr>
      {user === null ? (
        <Link to="/login" onClick={onClose}>
          <Row>
            <div className="container mt15">
              <div className="row justify-content-center">
                <div className="login-button text-center pointer">Login</div>
              </div>
            </div>
          </Row>
        </Link>
      ) : (
        <Row onClick={() => handleLogout()}>
          <div className="container mt15">
            <div className="row justify-content-center">
              <div className="login-button text-center pointer">Logout</div>
            </div>
          </div>
        </Row>
      )}
    </Container>
  );
}
