import { useState, useEffect } from 'react';
import { Alert, FormControl } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getEvoGamesReq } from 'stores/auth/services';
import Loading from 'components/general/loading';
import { startLiveEvoCasinoSessionReq } from 'stores/auth/services';
import { getLanguageCode } from 'toolkit/utils';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

const defaultGameGroups = [
  {
    groupId: 29,
    key: 'roulette',
    title: 'Roulette',
    games: []
  },
  {
    groupId: 32,
    key: 'blackjack',
    title: 'Blackjack',
    games: []
  },
  {
    groupId: 31,
    key: 'baccarat',
    title: 'Baccarat',
    games: []
  },
  {
    groupId: 30,
    key: 'poker',
    title: 'Others',
    games: []
  },
];

export default function EvoGames() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useSelector((state) => state.auth.user);
  const [gameGroups, setGameGroups] = useState();
  const [filteredGameGroups, setFilteredGameGroups] = useState();
  const [iframeSrc, setIframeSrc] = useState('');
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(true);
  const [error, setError] = useState(false);

  async function getData() {
    try {
      setLoading(true);
      const response = await getEvoGamesReq();
      const tempGameGroups = [...defaultGameGroups];
      response.forEach((game) => {
        const gameGroupIndex = tempGameGroups.findIndex((g) => game.categories.includes(g.groupId));
        if (gameGroupIndex > -1) {
          tempGameGroups[gameGroupIndex].games.push(game);
        }
      });
      setGameGroups(tempGameGroups);
      setFilteredGameGroups(tempGameGroups);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }

  function onGameClick(game) {
    startSession(game.id);
  }

  function onChange(e) {
    const value = e.target.value;
    const tempFilteredGameGroups = gameGroups.map((gameGroup) => {
      return {
        ...gameGroup,
        games: gameGroup.games.filter((game) =>
          game.name.toLowerCase().includes(value.toLowerCase())
        )
      };
    });
    setFilteredGameGroups(tempFilteredGameGroups);
  }

  function startSession(gameId) {
    startLiveEvoCasinoSessionReq({
      gameId: gameId,
      customerId: user.id,
      language: getLanguageCode().split('-')[0],
      exitUrl: 'live90.bet'
    })
      .then((r) => {
        setIframeSrc(r.data);
        setSearchParams({ game: 'evo' });
      })
      .catch((e) => {
        setError(e);
      });
  }

  useEffect(() => {
    getData();
    setSearchParams({});
  }, []);

  useEffect(() => {
    if (!searchParams.has('game')) {
      setIframeSrc('');
    }
  }, [searchParams]);

  const setIframeHeightOnLoad = () => {
    const iframe = document.getElementById('casinoFrame');
    if (iframe) {
      iframe.style.height = `${window.innerHeight - 56}px`;
    }
  };

  if (iframeSrc) {
    return (
      <>
        {showAlert && (
          <Alert
            variant={'primary'}
            className="py-3 mt-n2"
            onClose={() => setShowAlert(false)}
            dismissible>
            Maximum winning limit is {user.currency === 0 ? '15000 €' : '50000 ₺'}
          </Alert>
        )}

        <iframe
          src={iframeSrc}
          id="casinoFrame"
          className=""
          sandbox="allow-same-origin allow-top-navigation allow-popups allow-scripts"
          onLoad={setIframeHeightOnLoad}
          style={{
            width: '100vw',
            position: 'fixed',
            top: showAlert ? 110 : 56,
            right: 0,
            border: 'none',
            zIndex: 1050
          }}
        />
      </>
    );
  }

  if (error) {
    return <Alert variant="danger">We are under maintenance Will fix soon</Alert>;
  }

  return (
    <>
      <FormControl className="mb-3" placeholder="Search" onChange={onChange} />
      <div className="">
        {loading ? (
          <Loading message={t('loading_games')} />
        ) : (
          <>
            {filteredGameGroups
              .filter((g) => g.games.length > 0)
              .map((gameGroup) => (
                <div key={gameGroup.key} className="mb-5">
                  <h3 className="text-start">{gameGroup.title}</h3>

                  <div
                    className="gap-3 overflow-x-auto"
                    style={{
                      display: 'grid',
                      gridAutoColumns: '90px',
                      gridAutoFlow: 'column',
                      gridTemplateRows: 'repeat(2, minmax(0, 1fr))'
                    }}>
                    {gameGroup.games.map((game) => (
                      <div key={'game' + game.id} onClick={() => onGameClick(game)}>
                        <img className="rounded" height={90} width={90} src={game.image} alt="" />
                        <div className="fs-8">{game.name}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
    </>
  );
}
