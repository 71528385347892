/* eslint-disable no-prototype-builtins */
import { useState, useEffect, Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import * as _ from 'lodash';
import { Col, Row, Container } from 'react-bootstrap';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import MatchCard from 'components/general/match_card.js';
import {
  getSportTypeToActiveIcon,
  getSportTypeToWhiteIcon,
  sportIdToSportType,
  sportTypetToSportIdString,
  getDeviceType
} from 'toolkit/utils';
import SportDetailItem from 'components/general/SportDetailItem.js';
import { updateMatch } from 'stores/match';
import {
  fetchLiveMatches,
  setNextLiveMatches,
  setSelectedLeague,
  setSelectedSport
} from 'stores/match';
import eventsToGroup from 'toolkit/eventsToGroup.js';
import axios from 'axios';
import {
  TopLeaguesImages,
  TMM_URL,
  calculateSportCount,
  AWS_BUCKET_URL
} from 'toolkit/constants.js';
import { fetchBalance } from 'stores/auth';

function Start() {
  const dispatch = useDispatch();
  const selectedSport = useSelector((state) => state.match.selectedSport);
  const selectedLeague = useSelector((state) => state.match.selectedLeague);
  const selectedLocale = useSelector((state) => state.match.selectedLocale);
  const locales = useSelector((state) => state.match.locales);
  const counts = useSelector((state) => state.match.counts);
  const { user } = useSelector((state) => state.auth);
  const liveEvents = useSelector((state) => {
    const { liveMatches, events } = state.match;
    return liveMatches.map((c) => events[c]);
  }, shallowEqual);

  const { t } = useTranslation('translations', { useSuspense: false });
  const [lastScrollPosition, setLastScrollPosition] = useState(
    localStorage.getItem('scrollPosition') || 0
  );
  const [sports, setSports] = useState({});
  const [sport, setSport] = useState('');
  const [liveSports, setLiveSports] = useState({});
  const [selectedMarketType, setSelectedMarketType] = useState(7);
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [nextPageOnProgress, setNextPageOnProgress] = useState(false);
  const [preMatches, setPreMatches] = useState({});
  const [leagueMatches, setLeagueMatches] = useState({});

  const handleLocationScroll = () => {
    const currentPosition = window.pageYOffset || document.documentElement.scrollTop;
    setLastScrollPosition(currentPosition);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleLocationScroll);

    return () => {
      window.removeEventListener('scroll', handleLocationScroll);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('scrollPosition', lastScrollPosition.toString());
  }, [lastScrollPosition]);

  useEffect(() => {
    getCounts();
    dispatch(fetchLiveMatches());
    dispatch(fetchBalance());
  }, []);

  function getCounts() {
    let s = Object.keys(counts);
    setSports(s);
    if (s.length > 0) setSport(sportTypetToSportIdString(s[0]));
  }

  function getPreMatches(page) {
    axios
      .post(`${TMM_URL}/BetEvent/GetEventsBySportByPagination`, {
        pageSize: 20,
        page: page,
        sport: sport,
        hideLiveMatches: true
      })
      .then((result) => {
        let filteredMatches = result.data.data.betEvents.map((m) => {
          return { ...m, markets: m.markets.filter((m) => m.outcomes.length > 0) };
        });
        // events
        const data = { events: filteredMatches };
        dispatch(updateMatch(data));

        let finalArray = [];
        let topMatchDateEvents = _.groupBy(filteredMatches, (m) => m.gameDate);
        let dateKeys = Object.keys(topMatchDateEvents);
        dateKeys.sort((m1, m2) => new Date(m1) - new Date(m2));
        for (let i = 0; i < dateKeys.length; i++) {
          let leagueGroups = _.groupBy(topMatchDateEvents[dateKeys[i]], (m) => m.leagueId);
          for (let leagueGroupsKey in leagueGroups) {
            //finalArray = finalArray.concat(leagueGroups[leagueGroupsKey]);
            let league = leagueGroups[leagueGroupsKey][0].league;
            let category = leagueGroups[leagueGroupsKey][0].category;
            finalArray.push({
              category,
              league,
              matches: leagueGroups[leagueGroupsKey].map((m) => m.gameNumber)
            });
          }
        }

        if (page === 0) setPreMatches(finalArray);
        else setPreMatches(preMatches.concat(finalArray));

        if (result.data.data.betEvents.length === 20) {
          setPage(page + 1);
          setNextPageOnProgress(false);
        }
      })
      .catch(() => {
        setNextPageOnProgress(false);
      });
  }

  function getLeagueMatches() {
    axios
      .post(`${TMM_URL}/BetEvent/GetEventsByTournamentWithHours`, {
        hours: 0,
        tournament: selectedLeague
      })
      .then((result) => {
        let filteredMatches = result.data.data.betEvents
          .sort((a, b) => {
            const dateA = new Date(a.gameDate) ?? 0;
            const dateB = new Date(b.gameDate) ?? 0;
            return dateA - dateB;
          })
          .map((m) => {
            return { ...m, markets: m.markets.filter((m) => m.outcomes.length > 0) };
          });
        const data = { events: filteredMatches };
        dispatch(updateMatch(data));

        let category = filteredMatches[0]?.category;
        let league = filteredMatches[0]?.league;
        setLeagueMatches([{ league, category, matches: filteredMatches.map((m) => m.gameNumber) }]);
      })
      .catch(() => {});
  }

  useEffect(() => {
    // if (loading) return;
    const groups = eventsToGroup(liveEvents);
    setLiveSports(groups);
    if (sport === '' && liveEvents.length > 0) {
      const type =
        selectedSport !== null || sports.hasOwnProperty(Number.parseInt(selectedSport)) === true
          ? selectedSport
          : Object.keys(groups)[0];

      setSport(type);
    }
  }, [sport, liveEvents]);

  useEffect(() => {
    if (sport !== '') {
      dispatch(setSelectedSport(sport));
    }
  }, [sport]);

  useEffect(() => {
    setPage(0);
    setNextPageOnProgress(false);
    if (sport !== '' && selectedLeague === '') getPreMatches(0);
  }, [sport]);

  useEffect(() => {
    if (selectedLeague === '') return;
    setPage(0);
    setNextPageOnProgress(false);
    setSport('');
    getLeagueMatches();
  }, [selectedLeague]);

  function nextPage(p) {
    if (nextPageOnProgress) return;
    setNextPageOnProgress(true);
    getPreMatches(p);
  }

  const selectSport = (id) => {
    setSport(id);
    dispatch(setSelectedLeague(''));
    dispatch(setNextLiveMatches());
  };

  const handleScroll = () => {
    const container = window;
    const stickyEl = document.querySelector('.tip-types');
    if (!stickyEl) return;
    const scrollTop = container.scrollY;
    const direction = scrollTop < lastScrollPosition ? 'up' : 'down';
    setLastScrollPosition(scrollTop);

    if (scrollTop > 110) {
      stickyEl.classList.add('fixed');
      if (direction === 'down') {
        if (scrollTop > 200) {
          stickyEl.classList.remove('fixed');
        }
      } else {
        if (scrollTop > 200) {
          stickyEl.classList.add('fixed');
        }
      }
    } else {
      stickyEl.classList.remove('fixed');
    }
  };

  if (window) {
    window.removeEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll);
  }

  function calculateMenuItemsDynamic() {
    const menuItems = [];
    let icon = '';
    if (user && user.isCasinoEnabled) {
      // push casino icons
      let casinoIcon = (
        <Link to={{ pathname: '/casinobase' }} className="sport-item" key={'live-casino'}>
          <div className={'icon-size'}>
            <img
              width="25"
              height="25"
              className="icon-color"
              src={`${AWS_BUCKET_URL}/Icons/liveicon.svg`}
            />
          </div>
          <div>
            <span className={'sport-item-text'} style={{ color: 'white' }}>
              {t('live') + t('casino')}
            </span>
          </div>
        </Link>
      );
      if (getDeviceType() === 'iphone') {
        casinoIcon = (
          <Link to={{ pathname: '/casinobase' }} className="sport-item" key={'live-casino'}>
            <div className={'icon-size'}>
              <img
                width="25"
                height="25"
                className="icon-color"
                src={`${AWS_BUCKET_URL}/Icons/liveicon.svg`}
              />
            </div>
            <div>
              <span className={'sport-item-text'} style={{ color: 'white' }}>
                {t('live') + t('casino')}
              </span>
            </div>
          </Link>
        );
      }
      menuItems.push(casinoIcon);

      menuItems.push(
        <Link to={{ pathname: '/slot' }} className="sport-item" key={'slot'}>
          <div className={'icon-size'}>
            <img
              width="25"
              height="25"
              className="icon-color"
              src={`${AWS_BUCKET_URL}/Icons/sloticon.svg`}
            />
          </div>
          <div>
            <span className={'sport-item-text'} style={{ color: 'white' }}>
              {t('slot')}
            </span>
          </div>
        </Link>
      );
    }

    Object.keys(sports).map((sportType, index) => {
      if (sportType === sport) {
        icon = getSportTypeToActiveIcon(sport);
      } else {
        icon = getSportTypeToWhiteIcon(sportType);
      }
      if (
        counts[sportIdToSportType(sportType)] &&
        Object.keys(counts[sportIdToSportType(sportType)]).length
      ) {
        menuItems.push(
          <SportDetailItem
            key={index}
            sportType={sportType}
            selectedSportType={sport}
            active={sport === sportType}
            count={calculateSportCount(counts, sportIdToSportType(sportType))}
            icon={icon}
            onClick={() => {
              selectSport(sportType);
            }}
            text={t('sports.' + sportType)}
          />
        );
      }
    });

    return menuItems;
  }

  function getBetType() {
    const betTypes = [
      {
        label: 'RoM',
        id: 7
      },
      {
        label: 'Winner',
        id: 1
      },
      {
        label: 'O/U',
        id: 18
      },
      {
        label: 'HC',
        id: 14
      },
      {
        label: 'NG',
        id: 8
      },
      {
        label: 'BS',
        id: 29
      }
    ];
    return (
      <div className="tip-types shadow">
        {betTypes.map((betType) => (
          <div className="tip-type" key={betType.id}>
            <button
              className={`btn btn-light btn-sm w-100 border fs-12 ${
                selectedMarketType === betType.id ? 'selected' : ''
              }`}
              onClick={() => setSelectedMarketType(betType.id)}>
              {betType.label}
            </button>
          </div>
        ))}
      </div>
    );
  }

  function getBets(matches) {
    const matchCards = [];
    const liveMatchIds = [];
    if (liveSports[sport] && liveSports[sport].length > 0 && selectedLeague === '') {
      liveSports[sport]
        .filter((l) => l.matches.length > 0)
        .forEach((liveMatch) => {
          matchCards.push(
            <Fragment key={liveMatch.matches[0].providerId}>
              {liveMatch.matches.length > 0 && (
                <div className="league-names pt-0">
                  {liveMatch.category + ' / ' + liveMatch.league}
                </div>
              )}
              {liveMatch.matches.map(
                (match, index) => (
                  liveMatchIds.push(match.providerId),
                  (
                    <>
                      <MatchCard
                        key={match.providerId}
                        providerId={match.providerId}
                        marketType={selectedMarketType}
                        index={index}
                        sport={sport}
                        path={location.pathname}
                        locales={locales}
                        selectedLocale={selectedLocale}
                      />
                    </>
                  )
                )
              )}
            </Fragment>
          );
        });
    }

    matches.forEach((prematch) => {
      matchCards.push(
        <Fragment key={prematch.league + prematch.matches[0]}>
          {prematch.category && prematch.matches.length > 0 && (
            <div className="league-names pt-0">{prematch.category + ' / ' + prematch.league}</div>
          )}
          {prematch.matches
            .filter((obj) => !liveMatchIds.includes(obj))
            .sort(
              () => new Date(prematch.gameDate).getTime() - new Date(prematch.gameDate).getTime()
            )
            .map((match, index) => (
              <>
                <MatchCard
                  key={match}
                  providerId={match}
                  marketType={selectedMarketType}
                  index={index}
                  path={location.pathname}
                  locales={locales}
                  selectedLocale={selectedLocale}
                />
              </>
            ))}
        </Fragment>
      );
    });
    return matchCards;
  }
  function leagueSelected(leagueId) {
    dispatch(setSelectedLeague(leagueId));
    setSport('0');
  }

  function checkSportTypes(sport) {
    if (
      sport === '1' ||
      sport === '2' ||
      sport === '4' ||
      sport === '3' ||
      sport === '6' ||
      sport === '12' ||
      sport === '13' ||
      sport === '5' ||
      sport === '11' ||
      sport === '14'
    ) {
      return true;
    }
    return false;
  }

  function getBetTypeTitle() {
    if (selectedMarketType === 29) {
      return (
        <Row>
          <Col xs={4} className="fs-16"></Col>
          <Col xs={4} className="fs-16">
            Yes
          </Col>
          <Col xs={4} className="fs-16" style={{ display: 'flex' }}>
            No
          </Col>
        </Row>
      );
    }
    if (selectedMarketType === 8) {
      return (
        <Row>
          <Col xs={4} className="fs-16">
            1
          </Col>
          <Col xs={4} className="fs-16">
            X
          </Col>
          <Col xs={4} className="fs-16">
            2
          </Col>
        </Row>
      );
    }
    if (selectedMarketType === 14) {
      return (
        <Row>
          <Col xs={4} className="fs-16">
            1
          </Col>
          <Col xs={4} className="fs-16">
            X
          </Col>
          <Col xs={4} className="fs-16">
            2
          </Col>
        </Row>
      );
    }
    if (selectedMarketType === 18) {
      return (
        <Row>
          <Col xs={4} className="fs-16"></Col>
          <Col xs={4} className="fs-16">
            +
          </Col>
          <Col xs={4} className="fs-16">
            -
          </Col>
        </Row>
      );
    }
    if (selectedMarketType === 1 || selectedMarketType === 7) {
      return (
        <Row>
          <Col xs={4} className="fs-16">
            1
          </Col>
          <Col xs={4} className="fs-16">
            X
          </Col>
          <Col xs={4} className="fs-16">
            2
          </Col>
        </Row>
      );
    }
  }

  function fillLeagues() {
    let leagues = [];
    TopLeaguesImages.map((league) => {
      let topLeagueItem;
      topLeagueItem = (
        <a
          key={league.betradarCategoryId}
          onClick={() => {
            leagueSelected(league.betradarCategoryId);
          }}
          className="league">
          {<img width="30" height="25" src={league.imageName} />}
          <br />
          {
            <a
              className={`${league.betradarCategoryId === selectedLeague ? 'league-selected' : ''}`}
              style={{ fontSize: 9, fontWeight: 700 }}>
              {league.leagueName}
            </a>
          }
        </a>
      );
      leagues.push(topLeagueItem);
    });
    return leagues;
  }

  return (
    <Container fluid className="g-0">
      <InfiniteLoadComponent
        nextPage={() => {
          nextPage(page);
        }}
        className="scrollable-y"
        style={{ height: '100vh' }}>
        <div className="scrollmenuFullWidth blackBgColor">{calculateMenuItemsDynamic()}</div>
        <div className="leagues">{fillLeagues()}</div>
        {sport === '0' && (
          <>
            <Row>
              <Col className="tip-types-container">{getBetType()}</Col>
            </Row>
            <Row>
              <Col></Col>
              <Col>{getBetTypeTitle(selectedMarketType)}</Col>
            </Row>
          </>
        )}

        {sport !== '0' && checkSportTypes(sport) && (
          <>
            {/* <Row>
              <Col className="tip-types-container"></Col>
            </Row> */}
            <Row>
              <Col></Col>
              <Col>
                <Row>
                  <Col xs={2} style={{ fontSize: 16 }}></Col>
                  <Col xs={5} style={{ fontSize: 16, textAlign: 'end' }}>
                    1
                  </Col>
                  <Col xs={5} style={{ fontSize: 16 }}>
                    2
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}

        {sport !== '0' && !checkSportTypes(sport) && (
          <>
            {/* <Row>
              <Col className="tip-types-container"></Col>
            </Row> */}
            <Row>
              <Col></Col>
              <Col>
                <Row>
                  <Col xs={4} style={{ fontSize: 16, textAlign: 'end' }}>
                    1
                  </Col>
                  <Col xs={4} style={{ fontSize: 16 }}>
                    0
                  </Col>
                  <Col xs={4} style={{ fontSize: 16, textAlign: 'justify' }}>
                    2
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}

        <section className="mb-3">
          {selectedLeague != '' &&
            leagueMatches &&
            leagueMatches.length > 0 &&
            getBets(leagueMatches)}

          {selectedLeague === '' &&
            preMatches &&
            preMatches.length > 0 &&
            getBets(preMatches, false)}
        </section>
      </InfiniteLoadComponent>
    </Container>
  );
}

const InfiniteLoadComponent = (props) => {
  const node = useRef();

  useEffect(() => {
    const savedScrollPosition = localStorage.getItem('scrollPosition');
    if (savedScrollPosition) {
      //window.scrollTo(0, parseInt(savedScrollPosition));
    }
  }, []);

  const [lastScrollPosition, setLastScrollPosition] = useState(
    localStorage.getItem('scrollPosition') || 0
  );

  useEffect(() => {
    localStorage.setItem('scrollPosition', lastScrollPosition.toString());
  }, [lastScrollPosition]);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = node.current;

    const stickyEl = document.querySelector('.tip-types');
    if (!stickyEl) return;
    const direction = scrollTop < lastScrollPosition ? 'up' : 'down';
    setLastScrollPosition(scrollTop);

    if (scrollTop > 110) {
      stickyEl.classList.add('fixed');
      if (direction === 'down') {
        if (scrollTop > 200) {
          stickyEl.classList.remove('fixed');
        }
      } else {
        if (scrollTop > 200) {
          stickyEl.classList.add('fixed');
        }
      }
    } else {
      stickyEl.classList.remove('fixed');
    }

    const currentPosition = window.pageYOffset || scrollTop;
    setLastScrollPosition(currentPosition);
    if (clientHeight + scrollTop >= scrollHeight - 100) {
      props.nextPage();
    }
  };

  useEffect(() => {
    if (node.current) {
      node.current.addEventListener('scroll', handleScroll);
      return () => {
        if (node.current) node.current.removeEventListener('scroll', handleScroll);
      };
    }
  }, [node, handleScroll]);

  return (
    <div {...props} ref={node}>
      {props.children}
    </div>
  );
};

export default Start;
