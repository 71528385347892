import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { setSelectedMatch, fetchFullEvent } from 'stores/match/index.js';
import { BetGroups, getBetGroups } from 'toolkit/constants.js';
import {
  MarketIdToMarketName,
  ReverseName,
  checkHasMarketVariable,
  getLanguageCode,
  gameDateToMatchDayFormatted,
  sportTypeToSportId
} from 'toolkit/utils';
import { AWS_BUCKET_URL } from 'toolkit/constants';
import { getMatchTime } from 'toolkit/utils.js';
import { EnumMarketName, EnumEventStatus } from 'toolkit/Enums.js';
import { getMatchDetail, getTeamNameHelper } from 'helper/matchDetailHelper';
import MatchTracker from '../match_tracker/match_tracker.js';
import BetBox from './bet_box';
import GoBack from './go_back';
import BetTitleAndBox from './bet_title_and_box.js';
import { EBetGroupName } from 'toolkit/Enums.js';

function MatchDetail({ matchDetailId, onMatchDetailIdSet }) {
  const dispatch = useDispatch();
  const { t } = useTranslation('translations', { useSuspense: false });
  const location = useLocation();
  const navigate = useNavigate();
  const providerId = matchDetailId || location.hash.slice(1);
  const match = useSelector((state) => state.match.events[providerId]);
  const selectedLocale = useSelector((state) => state.match.selectedLocale);
  const locales = useSelector((state) => state.match.locales);
  const [matchDetail, setMatchDetail] = useState(undefined);
  const [availableMarketGroups, setAvailableMarketGroups] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState(EBetGroupName.TOP_BETS);
  const [matchtrackerCallback, setMatchtrackerCallback] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchMatch();
  }, []);

  function fetchMatch() {
    dispatch(setSelectedMatch(providerId));
    dispatch(fetchFullEvent(providerId))
      .unwrap()
      .then((response) => {
        if (!response) {
          navigate('/live');
        }
      });
  }

  useEffect(() => {
    if (match) {
      setMatchDetail(getMatchDetail(match));
    }
  }, [match]);

  useEffect(() => {
    getMarketGroups();
  }, [match]);

  function getMarketGroups() {
    if (match === undefined) return;
    const tempAvailableTabOrders = new Map();
    match.markets.forEach((m) => {
      const betGroupNames = getBetGroups(m.id) ?? [EBetGroupName.SPECIAL_BETS];
      betGroupNames.map((betGroupName) => {
        if (!tempAvailableTabOrders.has(betGroupName)) {
          tempAvailableTabOrders.set(betGroupName, BetGroups[betGroupName].tabOrder);
        }
      });
    });

    setAvailableMarketGroups(
      Array.from(tempAvailableTabOrders.entries())
        .sort(([, tabOrderA], [, tabOrderB]) => tabOrderA - tabOrderB)
        .map(([name]) => {
          return name;
        })
    );

    if (tempAvailableTabOrders.length == 1) {
      setSelectedMarket(tempAvailableTabOrders[0]);
    }
  }

  function getGoalScorers(outcomeId, marketId) {
    const market = match.markets.find((x) => x.id === marketId);
    if (market) {
      return (
        <Col xs={2} className="m-0 p-1">
          <BetBox
            key={outcomeId}
            providerId={providerId}
            outcomeId={outcomeId}
            marketStringId={market.stringId}
            showOddTitle={false}
          />
        </Col>
      );
    } else {
      return (
        <Col xs={2} className="m-0 p-1">
          <BetBox
            key={outcomeId}
            providerId={providerId}
            outcomeId={outcomeId}
            marketStringId={null}
            showOddTitle={false}
          />
        </Col>
      );
    }
  }

  const sortByMarketId = (arr) => {
    return _.orderBy(arr, (market) => {
      const marketVariable = checkHasMarketVariable(market.id, market.stringId);
      if (marketVariable) {
        if (!isNaN(Number(marketVariable))) return Number(marketVariable);
        return marketVariable;
      }
      return false;
    });
  };

  function getMarkets(betGroupName) {
    let markets;
    if (betGroupName === EBetGroupName.SPECIAL_BETS) {
      const includedBetId = Object.values(BetGroups).flatMap((group) => group.markets);
      const notIncludedBets = match.markets.filter((item) => !includedBetId.includes(item.id));
      markets = [
        ...match.markets.filter((item) => BetGroups[betGroupName].markets.includes(item.id)),
        ...notIncludedBets
      ];
    } else {
      markets = [
        ...match.markets
          .filter((item) => BetGroups[betGroupName].markets.includes(item.id))
          .map((m) => ({ ...m, outcomes: [...m.outcomes] }))
      ];
    }
    const marketList = [];

    // Top Bets Markets Groups
    if (betGroupName === EBetGroupName.TOP_BETS) {
      const correctScore = [];
      let targetArr;
      markets = markets.sort((t1, t2) => t1.id - t2.id);

      // //Remove other next goal markets
      // let nextGoalMinBySpecifier = { market: null, specifier: 9999 };

      // markets.forEach((market) => {
      //   const specifier = Number(market.stringId.split(':')[1] ?? -1);
      //   if (market.id == 8 && specifier > -1) {
      //     if (specifier < nextGoalMinBySpecifier.specifier) {
      //       nextGoalMinBySpecifier = { market, specifier };
      //     }
      //   }
      // });
      // if (nextGoalMinBySpecifier.market) {
      //   markets = markets.filter((m) => m.id !== 8);
      //   markets.push(nextGoalMinBySpecifier.market);
      // }
      // Remove other next goal markets end

      const marketRom = markets.find((x) => x.id == EnumMarketName.ROM_7);
      if (marketRom) {
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'market-list-' + marketRom.stringId}>
            <BetTitleAndBox
              key={marketRom.stringId}
              market={marketRom}
              matchId={providerId}
              matchDetail={matchDetail}
              sportType={match.sport}
            />
          </div>
        );
      }
      if (match.sport == sportTypeToSportId('Football')) {
        markets.forEach((market) => {
          targetArr = market.id == EnumMarketName.CORRECTSCORE_41 ? correctScore : marketList;
          if (
            market.id != EnumMarketName.ROM_7 &&
            market.id != EnumMarketName.HANDICAP_14 &&
            market.id != EnumMarketName.HANDICAP_16 &&
            market.id != EnumMarketName.TOTAL_18 &&
            market.id != EnumMarketName.FIRSTHALFWINNER_60 &&
            market.id != EnumMarketName.FIRSTHALFHANDICAP_65 &&
            market.id != EnumMarketName.FIRSTHALFHANDICAP_66 &&
            market.id != EnumMarketName.FIRSTHALFOVERUNDER_68 &&
            market.id != 180
          ) {
            targetArr.push(
              <div
                className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
                key={
                  'd-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-' + market.stringId
                }>
                <BetTitleAndBox
                  key={market.stringId}
                  market={market}
                  matchId={providerId}
                  matchDetail={matchDetail}
                  sportType={match.sport}
                />
              </div>
            );
          }
        });
        if (correctScore.length > 0)
          marketList.push(
            <div key={'d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-correctScore'}>
              {correctScore}
            </div>
          );
        return marketList;
      } else {
        const firstHalfOverUnderList = [];
        const overUnderList = [];
        const firstHalfHandicap = [];
        const handicapList = [];
        const overUnderGamesList = [];
        const gameHandicapList = [];
        markets = _.orderBy(markets, (market) => {
          if (
            market.id == EnumMarketName.TOTAL_18 ||
            market.id == EnumMarketName.HOMETOTAL_19 ||
            market.id == EnumMarketName.AWAYTOTAL_20 ||
            market.id == EnumMarketName.FIRSTHALFOVERUNDER_68 ||
            market.id == EnumMarketName.TOTAL_225
          )
            return market.stringId.split(':')[1];
        });

        markets.forEach((market) => {
          if (market.id != EnumMarketName.ROM_7) {
            let checkArrLength = true;
            let betItem = (
              <BetTitleAndBox
                key={market.stringId}
                market={market}
                matchId={providerId}
                matchDetail={matchDetail}
                sportType={match.sport}
              />
            );
            if (
              market.id == EnumMarketName.TOTAL_18 ||
              market.id == EnumMarketName.TOTAL_225 ||
              market.id == EnumMarketName.TOTALPOINT_238 ||
              market.id == EnumMarketName.TOTAL_258 ||
              market.id == EnumMarketName.TOTALFRAMES_494
            ) {
              market.outcomes = _.orderBy(market.outcomes, ['id'], ['asc']);
              targetArr = overUnderList;
            } else if (market.id == EnumMarketName.FIRSTHALFHANDICAP_66) {
              targetArr = firstHalfHandicap;
            } else if (market.id == EnumMarketName.WINNER_219) {
              checkArrLength = false;
              targetArr = correctScore;
            } else if (market.id == EnumMarketName.FIRSTHALFOVERUNDER_68) {
              targetArr = firstHalfOverUnderList;
            } else if (market.id == EnumMarketName.GAMEHANDICAP_187) {
              targetArr = gameHandicapList;
            } else if (market.id == EnumMarketName.TOTALGAMES_189) {
              targetArr = overUnderGamesList;
            } else if (
              market.id == EnumMarketName.HANDICAP_14 ||
              market.id == EnumMarketName.HANDICAP_16 ||
              market.id == EnumMarketName.HANDICAP_223 ||
              market.id == EnumMarketName.HANDICAP_256 ||
              market.id == EnumMarketName.POINTHANDICAP_237 ||
              market.id == EnumMarketName.FRAMEHANDICAP_493
            ) {
              targetArr = handicapList;
            } else {
              checkArrLength = false;
              targetArr = marketList;
              betItem = (
                <div
                  className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
                  key={
                    'd-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-' + market.stringId
                  }>
                  {betItem}
                </div>
              );
            }
            if (checkArrLength && targetArr.length == 0) {
              targetArr.push(
                <div className="text-start fs-6" key={'bet_name' + market.stringId}>
                  {MarketIdToMarketName(market.id, market.stringId).split(' + ')[0]}
                </div>
              );
            }
            targetArr.push(betItem);
          }
        });
        if (correctScore.length > 0)
          marketList.push(
            <div
              className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
              key={'d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-correctScore'}>
              {correctScore}
            </div>
          );
        if (firstHalfHandicap.length > 0)
          marketList.push(
            <div
              className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
              key={'d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-firstHalfHandicap'}>
              {firstHalfHandicap}
            </div>
          );
        if (handicapList.length > 0)
          marketList.push(
            <div
              className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
              key={'d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-handicap'}>
              {handicapList}
            </div>
          );
        if (overUnderList.length > 0)
          marketList.push(
            <div
              className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
              key={'d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-overUnder'}>
              {overUnderList}
            </div>
          );
        if (firstHalfOverUnderList.length > 0)
          marketList.push(
            <div
              className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
              key={'d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-firstHalfOverUnder'}>
              {firstHalfOverUnderList}
            </div>
          );
        if (overUnderGamesList.length > 0)
          marketList.push(
            <div
              className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
              key={'d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-overUnderGames'}>
              {overUnderGamesList}
            </div>
          );
        if (gameHandicapList.length > 0)
          marketList.push(
            <div
              className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
              key={'d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-gameHandicap'}>
              {gameHandicapList}
            </div>
          );
        return marketList;
      }
    }

    //Second Halftimer Markets Group
    if (betGroupName === EBetGroupName.SECOND_HALF_TIME) {
      const secondHalfOverUnder = [];
      const secondHalfHomeOverUnder = [];
      const secondHalfAwayOverUnder = [];
      markets = _.orderBy(markets, (market) => {
        if (market.id == EnumMarketName.SECONDHALFNEXTGOAL_84) {
          return market.stringId.split(':')[3];
        }
        if (
          market.id == EnumMarketName.SECONDHALFOVERUNDER_90 ||
          market.id == EnumMarketName.SECONDHALFHOMEOVERUNDER_91 ||
          market.id == EnumMarketName.SECONDHALFAWAYOVERUNDER_92
        )
          return market.stringId.split(':')[1];
      });

      markets = sortByMarketId(markets);
      markets = markets.sort((t1, t2) => t1.id - t2.id);
      const marketRom = markets.find((x) => x.id == 83);
      if (marketRom) {
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'market-list-' + marketRom.stringId}>
            <BetTitleAndBox
              key={marketRom.stringId}
              market={marketRom}
              matchId={providerId}
              matchDetail={matchDetail}
              sportType={match.sport}
            />
          </div>
        );
      }

      markets.forEach((market) => {
        if (market.id != EnumMarketName.SECONDHALFWINNER_83) {
          const marketId = market.id;
          let targetArr;
          let checkArrLength = true;
          let betItem = (
            <BetTitleAndBox
              key={market.stringId}
              market={market}
              matchId={providerId}
              matchDetail={matchDetail}
              sportType={match.sport}
            />
          );
          if (marketId == EnumMarketName.SECONDHALFOVERUNDER_90) {
            market.outcomes = _.orderBy(market.outcomes, ['id'], ['asc']);
            targetArr = secondHalfOverUnder;
          } else if (marketId == EnumMarketName.SECONDHALFHOMEOVERUNDER_91) {
            targetArr = secondHalfHomeOverUnder;
          } else if (marketId == EnumMarketName.SECONDHALFAWAYOVERUNDER_92) {
            targetArr = secondHalfAwayOverUnder;
          } else {
            if (
              marketId == EnumMarketName.SECONDHALFNEXTGOAL_84 ||
              marketId == EnumMarketName.SECONDHALFWINNER_83
            ) {
              market.outcomes = _.orderBy(market.outcomes, ['id'], ['asc']);
            }
            checkArrLength = false;
            targetArr = marketList;
            betItem = (
              <div
                className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
                key={
                  'd-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-' + market.stringId
                }>
                {betItem}
              </div>
            );
          }
          if (checkArrLength && targetArr.length == 0) {
            targetArr.push(
              <div className="text-start fs-6" key={'bet_name' + market.stringId}>
                {MarketIdToMarketName(market.id, market.stringId).split(' + ')[0]}
              </div>
            );
          }
          targetArr.push(betItem);
        }
      });

      if (secondHalfOverUnder.length > 0)
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'market-list-secondHalfOverUnder'}>
            {secondHalfOverUnder}
          </div>
        );
      if (secondHalfHomeOverUnder.length > 0)
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'market-list-secondHalfHomeOverUnder'}>
            {secondHalfHomeOverUnder}
          </div>
        );
      if (secondHalfAwayOverUnder.length > 0)
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'market-list-secondtHalfAwayOverUnder'}>
            {secondHalfAwayOverUnder}
          </div>
        );
      return marketList;
    }

    // Over Under Markets Groups
    if (betGroupName == EBetGroupName.Over_Under) {
      const overUnderList = [];
      const homeOverUnderList = [];
      const awayOverUnderList = [];
      const firstHalfOverUnderList = [];
      const overUnderGamesList = [];
      const otherList = [];
      const firstHalfAwayOverUnderList = [];
      const firstHalfHomeOverUnderList = [];
      const secondHalfOverUnderList = [];
      const secondHalfHomeOverUnderList = [];
      const secondHalfAwayOverUnderList = [];

      markets = _.orderBy(markets, (market) => {
        if (
          market.id == EnumMarketName.TOTAL_18 ||
          market.id == EnumMarketName.HOMETOTAL_19 ||
          market.id == EnumMarketName.AWAYTOTAL_20 ||
          market.id == EnumMarketName.FIRSTHALFOVERUNDER_68 ||
          market.id == EnumMarketName.TOTAL_225 ||
          market.id == EnumMarketName.FIRSTHALFHOMEOVERUNDER_69 ||
          market.id == EnumMarketName.FIRSTHALFAWAYOVERUNDER_70 ||
          market.id == EnumMarketName.SECONDHALFOVERUNDER_90 ||
          market.id == EnumMarketName.SECONDHALFHOMEOVERUNDER_91 ||
          market.id == EnumMarketName.SECONDHALFAWAYOVERUNDER_92
        )
          return market.stringId.split(':')[1];
      });
      markets.forEach((market) => {
        const marketId = market.id;
        let targetArr;
        let checkArrLength = true;
        let betItem = (
          <BetTitleAndBox
            key={market.stringId}
            market={market}
            matchId={providerId}
            matchDetail={matchDetail}
            sportType={match.sport}
          />
        );
        if (
          marketId == EnumMarketName.TOTAL_18 ||
          marketId == EnumMarketName.TOTAL_225 ||
          marketId == EnumMarketName.TOTALPOINT_238 ||
          marketId == EnumMarketName.TOTAL_258 ||
          marketId == EnumMarketName.TOTALFRAMES_494
        ) {
          market.outcomes = _.orderBy(market?.outcomes, ['id'], ['asc']);
          targetArr = overUnderList;
        } else if (marketId == EnumMarketName.HOMETOTAL_19) {
          market.outcomes = _.orderBy(market.outcomes, ['id'], ['asc']);
          targetArr = homeOverUnderList;
        } else if (marketId == EnumMarketName.AWAYTOTAL_20) {
          market.outcomes = _.orderBy(market.outcomes, ['id'], ['asc']);
          targetArr = awayOverUnderList;
        } else if (marketId == EnumMarketName.FIRSTHALFOVERUNDER_68) {
          market.outcomes = _.orderBy(market.outcomes, ['id'], ['asc']);
          targetArr = firstHalfOverUnderList;
        } else if (marketId == EnumMarketName.FIRSTHALFHOMEOVERUNDER_69) {
          market.outcomes = _.orderBy(market.outcomes, ['id'], ['asc']);
          targetArr = firstHalfHomeOverUnderList;
        } else if (marketId == EnumMarketName.FIRSTHALFAWAYOVERUNDER_70) {
          market.outcomes = _.orderBy(market.outcomes, ['id'], ['asc']);
          targetArr = firstHalfAwayOverUnderList;
        } else if (marketId == EnumMarketName.SECONDHALFOVERUNDER_90) {
          market.outcomes = _.orderBy(market.outcomes, ['id'], ['asc']);
          targetArr = secondHalfOverUnderList;
        } else if (marketId == EnumMarketName.SECONDHALFHOMEOVERUNDER_91) {
          market.outcomes = _.orderBy(market.outcomes, ['id'], ['asc']);
          targetArr = secondHalfHomeOverUnderList;
        } else if (marketId == EnumMarketName.SECONDHALFAWAYOVERUNDER_92) {
          market.outcomes = _.orderBy(market.outcomes, ['id'], ['asc']);
          targetArr = secondHalfAwayOverUnderList;
        } else if (marketId == EnumMarketName.TOTALGAMES_189) {
          market.outcomes = _.orderBy(market.outcomes, ['id'], ['asc']);
          targetArr = overUnderGamesList;
        } else {
          targetArr = otherList;
          checkArrLength = false;
          betItem = (
            <div
              className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
              key={'d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-' + market.stringId}>
              {betItem}
            </div>
          );
        }

        if (checkArrLength && targetArr.length == 0) {
          targetArr.push(
            <div className="text-start fs-6" key={'bet_name' + market.stringId}>
              {MarketIdToMarketName(market.id, market.stringId).split(' + ')[0]}
            </div>
          );
        }

        targetArr.push(betItem);
      });

      if (overUnderList.length > 0)
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-overUnder'}>
            {overUnderList}
          </div>
        );
      if (homeOverUnderList.length > 0)
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-homeOverUnder'}>
            {homeOverUnderList}
          </div>
        );
      if (awayOverUnderList.length > 0)
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-awayOverUnder'}>
            {awayOverUnderList}
          </div>
        );
      if (firstHalfOverUnderList.length > 0)
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'market-list-firstHalfOverUnder'}>
            {firstHalfOverUnderList}
          </div>
        );
      if (firstHalfAwayOverUnderList.length > 0)
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'market-list-firstHalfAwayOverUnderList'}>
            {firstHalfAwayOverUnderList}
          </div>
        );

      if (firstHalfHomeOverUnderList.length > 0)
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'market-list-firstHalfHomeOverUnderList'}>
            {firstHalfHomeOverUnderList}
          </div>
        );
      if (secondHalfOverUnderList.length > 0)
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'market-list-secondHalfOverUnderList'}>
            {secondHalfOverUnderList}
          </div>
        );
      if (secondHalfHomeOverUnderList.length > 0)
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'market-list-secondHalfHomeOverUnderList'}>
            {secondHalfHomeOverUnderList}
          </div>
        );
      if (secondHalfAwayOverUnderList.length > 0)
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'market-list-secondHalfAwayOverUnderList'}>
            {secondHalfAwayOverUnderList}
          </div>
        );
      if (overUnderGamesList.length > 0)
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-overUnderGames'}>
            {overUnderGamesList}
          </div>
        );
      if (otherList.length > 0) marketList.push(otherList);

      return marketList;
    }
    // 1st Halftime Markets Group
    if (betGroupName == EBetGroupName.FIRST_HALF_TIME) {
      const firstHalfHandicap = [];
      const firstHalfOverUnder = [];
      const firstHalfHomeOverUnder = [];
      const firstHalfAwayOverUnder = [];
      const firstHalfExactGoals = [];
      const multiGoals = [];
      const firstBothTeamsToScore = [];
      markets = _.orderBy(markets, (market) => {
        if (market.id == EnumMarketName.FIRSTHALFEXACTGOALS_71) {
          return market.stringId.split(':')[3];
        }
        if (
          market.id == EnumMarketName.FIRSTHALFOVERUNDER_68 ||
          market.id == EnumMarketName.FIRSTHALFHOMEOVERUNDER_69 ||
          market.id == EnumMarketName.FIRSTHALFAWAYOVERUNDER_70
        )
          return market.stringId.split(':')[1];
      });

      markets = sortByMarketId(markets);
      markets = markets.sort((t1, t2) => t1.id - t2.id);
      const marketRom = markets.find((x) => x.id == 61);
      if (marketRom) {
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'market-list-' + marketRom.stringId}>
            <BetTitleAndBox
              key={marketRom.stringId}
              market={marketRom}
              matchId={providerId}
              matchDetail={matchDetail}
              sportType={match.sport}
            />
          </div>
        );
      }

      markets.forEach((market) => {
        if (market.id != EnumMarketName.FIRSTHALFROM_61) {
          const marketId = market.id;
          let targetArr;
          let checkArrLength = true;
          let betItem = (
            <BetTitleAndBox
              key={market.stringId}
              market={market}
              matchId={providerId}
              matchDetail={matchDetail}
              sportType={match.sport}
            />
          );
          if (marketId == EnumMarketName.FIRSTHALFHANDICAP_65) {
            targetArr = firstHalfHandicap;
          } else if (marketId == EnumMarketName.FIRSTHALFOVERUNDER_68) {
            market.outcomes = _.orderBy(market.outcomes, ['id'], ['asc']);
            targetArr = firstHalfOverUnder;
          } else if (marketId == EnumMarketName.FIRSTHALFHOMEOVERUNDER_69) {
            targetArr = firstHalfHomeOverUnder;
          } else if (marketId == EnumMarketName.FIRSTHALFAWAYOVERUNDER_70) {
            targetArr = firstHalfAwayOverUnder;
          } else if (marketId == EnumMarketName.FIRSTHALFEXACTGOALS_71) {
            targetArr = firstHalfExactGoals;
          } else if (marketId == EnumMarketName.FIRSTHALFMULTIGOALS_552) {
            targetArr = multiGoals;
          } else if (marketId == EnumMarketName.FIRSTHALFBOTHTEAMSTOSCORE_75) {
            targetArr = firstBothTeamsToScore;
          } else {
            if (
              marketId == EnumMarketName.FIRSTHALFNEXTGOAL_62 ||
              marketId == EnumMarketName.FIRSTHALFROM_61
            ) {
              market.outcomes = _.orderBy(market.outcomes, ['id'], ['asc']);
            }
            checkArrLength = false;
            targetArr = marketList;
            betItem = (
              <div
                className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
                key={
                  'd-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-' + market.stringId
                }>
                {betItem}
              </div>
            );
          }
          if (checkArrLength && targetArr.length == 0) {
            targetArr.push(
              <div className="text-start fs-6" key={'bet_name' + market.stringId}>
                {MarketIdToMarketName(market.id, market.stringId).split(' + ')[0]}
              </div>
            );
          }
          targetArr.push(betItem);
        }
      });

      if (firstHalfHandicap.length > 0)
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-firstHalfHandicap'}>
            {firstHalfHandicap}
          </div>
        );
      if (firstHalfOverUnder.length > 0)
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'market-list-firstHalfOverUnder'}>
            {firstHalfOverUnder}
          </div>
        );
      if (firstHalfHomeOverUnder.length > 0)
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'market-list-firstHalfHomeOverUnder'}>
            {firstHalfHomeOverUnder}
          </div>
        );
      if (firstHalfAwayOverUnder.length > 0)
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'market-list-firstHalfAwayOverUnder'}>
            {firstHalfAwayOverUnder}
          </div>
        );
      if (firstHalfExactGoals.length > 0)
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'market-list-firstHalfExactGoals'}>
            {firstHalfExactGoals}
          </div>
        );
      if (firstBothTeamsToScore.length > 0) {
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'market-list-firstBothTeamsToScore'}>
            {firstBothTeamsToScore}
          </div>
        );
      }
      if (multiGoals.length > 0)
        marketList.push(
          <div className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2">
            {multiGoals}
          </div>
        );
      return marketList;
    }
    // Handicap Markets Group
    if (betGroupName == EBetGroupName.HANDICAP) {
      const handicapList = [];
      const firstHalfHandicap = [];
      const gameHandicap = [];
      markets = sortByMarketId(markets);
      markets.forEach((market) => {
        const marketId = market.id;
        let targetArr;
        let checkArrLength = true;
        let betItem = (
          <BetTitleAndBox
            key={market.stringId}
            market={market}
            matchId={providerId}
            matchDetail={matchDetail}
            sportType={match.sport}
          />
        );
        if (
          marketId == EnumMarketName.HANDICAP_14 ||
          marketId == EnumMarketName.HANDICAP_16 ||
          marketId == EnumMarketName.HANDICAP_223 ||
          marketId == EnumMarketName.HANDICAP_256 ||
          marketId == EnumMarketName.POINTHANDICAP_237 ||
          marketId == EnumMarketName.FRAMEHANDICAP_493
        ) {
          targetArr = handicapList;
        } else if (
          marketId == EnumMarketName.FIRSTHALFHANDICAP_65 ||
          marketId == EnumMarketName.FIRSTHALFHANDICAP_66
        ) {
          targetArr = firstHalfHandicap;
        } else if (marketId == EnumMarketName.gameHandicap) {
          targetArr = gameHandicap;
        } else {
          checkArrLength = false;
          targetArr = marketList;
          betItem = (
            <div
              className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
              key={'d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-' + market.stringId}>
              {betItem}
            </div>
          );
        }

        if (checkArrLength && targetArr.length == 0) {
          targetArr.push(
            <div className="text-start fs-6" key={'bet_name' + market.stringId}>
              {MarketIdToMarketName(market.id, market.stringId).split(' + ')[0]}
            </div>
          );
        }
        targetArr.push(betItem);
      });
      if (handicapList.length > 0)
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-handicap'}>
            {handicapList}
          </div>
        );
      if (firstHalfHandicap.length > 0)
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-firstHalfHandicap'}>
            {firstHalfHandicap}
          </div>
        );
      if (gameHandicap.length > 0)
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-gameHandicap'}>
            {gameHandicap}
          </div>
        );
      return marketList;
    }
    // Corner Markets group
    if (betGroupName === EBetGroupName.CORNER) {
      const cornerOverUnder = [];
      markets = sortByMarketId(markets);
      console.log(markets);
      markets.forEach((market, index) => {
        const marketId = market.id;
        let targetArr;
        let checkArrLength = true;
        let betItem = (
          <BetTitleAndBox
            key={index + market.stringId}
            market={market}
            matchId={providerId}
            matchDetail={matchDetail}
            sportType={match.sport}
          />
        );
        if (marketId == 166) {
          targetArr = cornerOverUnder;
        } else {
          targetArr = marketList;
          checkArrLength = false;
          betItem = (
            <div
              className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
              key={
                'd-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-' +
                market.stringId +
                index
              }>
              {betItem}
            </div>
          );
        }
        if (checkArrLength && targetArr.length == 0) {
          targetArr.push(
            <div className="text-start fs-6" key={'bet_name' + market.stringId + index}>
              {MarketIdToMarketName(market.id, market.stringId).split(' + ')[0]}
            </div>
          );
        }
        targetArr.push(betItem);
      });
      if (cornerOverUnder.length > 0)
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-cornerOverUnder'}>
            {cornerOverUnder}
          </div>
        );
      return marketList;
    }
    // Goals Markets Group
    if (betGroupName === EBetGroupName.GOALS) {
      const exactGoals = [];
      const goalRange = [];
      const multiScores = [];
      markets = _.orderBy(markets, (market) => {
        if (market.id == EnumMarketName.EXACTGOALS_21 || market.id == EnumMarketName.GOALRANGE_25) {
          return market.stringId.split(':')[3];
        }
      });

      //Remove other next goal markets
      // let nextGoalMinBySpecifier = { market: null, specifier: 9999 };

      // markets.forEach((market) => {
      //   const specifier = Number(market.stringId.split(':')[1] ?? -1);
      //   if (specifier > -1) {
      //     if (market.id == 8 && specifier < nextGoalMinBySpecifier.specifier) {
      //       nextGoalMinBySpecifier = { market, specifier };
      //     }
      //   }
      // });

      // if (nextGoalMinBySpecifier.market) {
      //   markets = markets.filter((m) => m.id !== 8);
      //   markets.push(nextGoalMinBySpecifier.market);
      // }
      // Remove other next goal markets end

      markets.forEach((market, index) => {
        const marketId = market.id;
        let targetArr;
        let checkArrLength = true;
        market.outcomes = _.orderBy(market.outcomes, ['id'], ['asc']);
        let betItem = (
          <BetTitleAndBox
            key={'group_6' + market.stringId + index}
            market={market}
            matchId={providerId}
            matchDetail={matchDetail}
            sportType={match.sport}
          />
        );
        if (marketId == EnumMarketName.EXACTGOALS_21) {
          targetArr = exactGoals;
        } else if (marketId == EnumMarketName.GOALRANGE_25) {
          market.outcomes = _.orderBy(market.outcomes, ['id'], ['asc']);
          targetArr = goalRange;
        } else if (marketId == EnumMarketName.MULTISCORES_551) {
          targetArr = multiScores;
        } else {
          checkArrLength = false;
          targetArr = marketList;
          betItem = (
            <div
              className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
              key={'d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-' + market.stringId}>
              {betItem}
            </div>
          );
        }
        if (checkArrLength && targetArr.length == 0) {
          targetArr.push(
            <div className="text-start fs-6" key={'bet_name' + market.stringId}>
              {MarketIdToMarketName(market.id, market.stringId).split(' + ')[0]}
            </div>
          );
        }
        targetArr.push(betItem);
      });

      if (exactGoals.length > 0)
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-exactGoals'}>
            {exactGoals}
          </div>
        );
      if (goalRange.length > 0)
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-goalRange'}>
            {goalRange}
          </div>
        );
      if (multiScores.length > 0)
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-multiScores'}>
            {multiScores}
          </div>
        );
      return marketList;
    }
    // Double Markets Group
    if (betGroupName === EBetGroupName.DOUBLE_BETS) {
      const winnerOverUnderList = [];
      const DoubleChanceAndOverUnder = [];

      markets = sortByMarketId(markets);

      markets.forEach((market, index) => {
        const marketId = market.id;
        let targetArr;
        let checkArrLength = true;
        let betItem = (
          <BetTitleAndBox
            key={'group_8' + market.stringId + index}
            market={market}
            matchId={providerId}
            matchDetail={matchDetail}
            sportType={match.sport}
          />
        );
        if (marketId == EnumMarketName.WINNERANDOVERUNDER_37) {
          targetArr = winnerOverUnderList;
        } else if (marketId == EnumMarketName.DOUBLECHANCETOTAL_547) {
          targetArr = DoubleChanceAndOverUnder;
        } else {
          checkArrLength = false;
          targetArr = marketList;
          betItem = (
            <div
              className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
              key={
                'd-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2--' +
                market.stringId +
                index
              }>
              {betItem}
            </div>
          );
        }
        if (checkArrLength && targetArr.length == 0) {
          targetArr.push(
            <div className="text-start fs-6" key={'bet_name' + market.stringId + index}>
              {MarketIdToMarketName(market.id, market.stringId).split(' + ')[0]}
            </div>
          );
        }
        if (checkArrLength) {
          targetArr.push(betItem, <hr></hr>);
        } else {
          targetArr.push(betItem);
        }
      });
      if (winnerOverUnderList.length > 0) {
        winnerOverUnderList.pop();
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-winneroverunder'}>
            {winnerOverUnderList}
          </div>
        );
      }
      if (DoubleChanceAndOverUnder.length > 0) {
        DoubleChanceAndOverUnder.pop();
        marketList.push(
          <div
            className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
            key={'d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2-doublechance'}>
            {DoubleChanceAndOverUnder}
          </div>
        );
      }
      return marketList;
    }
    // Player Markets Group
    if (betGroupName === EBetGroupName.PLAYER) {
      const goalScorerList = [];
      const anytimeGoalScorer = [];
      const anytimeWinner = [];
      const otherList = [];
      markets.forEach((market, index) => {
        const marketId = market.id;
        let targetArr;
        if (
          marketId == EnumMarketName.NEXTGOALSCORER_38 ||
          marketId == EnumMarketName.LASTGOALSCORER_39 ||
          marketId == EnumMarketName.ANYTIMEGOALSCORER_40
        ) {
          if (goalScorerList.length == 0) {
            goalScorerList.push(
              <div key={'footballer-name' + index}>
                <Row className="text-start fs-6">
                  <Col xs={6}>GoalScorer</Col>
                </Row>
                <Row>
                  <Col xs={6}></Col>
                  <Col xs={2} className="fs-8">
                    {t('oddType.first')}
                  </Col>
                  <Col xs={2} className="ps-0 pe-3 fs-8">
                    {t('oddType.anytime')}
                  </Col>
                  <Col xs={2} className="ps-2 pe-5 fs-8">
                    {t('oddType.last')}
                  </Col>
                </Row>
                {_.orderBy(market.outcomes, ['odds'], ['asc']).map((outcome) => (
                  <>
                    {outcome.odds == 0 || outcome.odds == 1.0 ? null : (
                      <Container>
                        <Row className="mb-1">
                          <Col xs={6} className="text-start fs-6">
                            {ReverseName(outcome.name)}
                          </Col>
                          {getGoalScorers(outcome.id, EnumMarketName.NEXTGOALSCORER_38)}
                          {getGoalScorers(outcome.id, EnumMarketName.ANYTIMEGOALSCORER_40)}
                          {getGoalScorers(outcome.id, EnumMarketName.LASTGOALSCORER_39)}
                        </Row>
                      </Container>
                    )}
                  </>
                ))}
              </div>
            );
          }
        } else if (
          marketId == EnumMarketName.ANYTIMEGOALSCORERWINNER_888 ||
          marketId == EnumMarketName.NEXTGOALSCORERWINNER_891
        ) {
          targetArr =
            marketId == EnumMarketName.ANYTIMEGOALSCORERWINNER_888
              ? anytimeGoalScorer
              : anytimeWinner;

          targetArr.push(
            <div key={'any-time-' + marketId + '' + index}>
              {targetArr.length === 0 && (
                <div className="text-start fs-6">
                  {MarketIdToMarketName(market.id, market.stringId)}
                </div>
              )}
              <BetTitleAndBox
                key={market.stringId}
                market={market}
                matchId={providerId}
                matchDetail={matchDetail}
                sportType={match.sport}
              />
            </div>
          );
        } else {
          otherList.push(
            <div key={'other-' + marketId + '' + index}>
              <Row className="text-start fs-6">
                {MarketIdToMarketName(market.id, market.stringId)}
              </Row>
              <BetTitleAndBox
                key={market.stringId}
                market={market}
                matchId={providerId}
                matchDetail={matchDetail}
                sportType={match.sport}
              />
            </div>
          );
        }
      });
      if (goalScorerList.length > 0)
        marketList.push(
          <div className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2">
            {goalScorerList}
          </div>
        );
      if (anytimeGoalScorer.length > 0)
        marketList.push(
          <div className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2">
            {anytimeGoalScorer}
          </div>
        );
      if (anytimeWinner.length > 0)
        marketList.push(
          <div className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2">
            {anytimeWinner}
          </div>
        );
      if (otherList.length > 0)
        marketList.push(
          <div className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2">
            {otherList}
          </div>
        );
      return marketList;
    }

    // if groups not matches any market do this//
    if (betGroupName == EBetGroupName.TRIPLE_BETS) {
      markets = markets.sort(
        (t1, t2) => Number(t1.stringId.split(':')[1]) - Number(t2.stringId.split(':')[1])
      );
    }
    markets.forEach((market) => {
      marketList.push(
        <div
          className="d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2"
          key={'d-flex flex-column gap-2 border-gray-300 rounded p-2 mb-2' + market.stringId}>
          <BetTitleAndBox
            key={market.stringId}
            market={market}
            matchId={providerId}
            matchDetail={matchDetail}
            sportType={match.sport}
          />
        </div>
      );
    });
    return marketList;
  }

  function PeriodScoreView() {
    let accumulatedHomeScore = 0;
    let accumulatedAwayScore = 0;
    return match?.periodScores?.map((periodScore, index) => {
      // Ignore for tennis and volleyball
      if (
        match.sport !== sportTypeToSportId('Tennis') &&
        match.sport !== sportTypeToSportId('Volleyball') &&
        index + 1 >= match.periodScores.length
      ) {
        return null;
      }
      if (match.sport == sportTypeToSportId('Basketball')) {
        // Basketball
        accumulatedHomeScore += periodScore.homeScore;
        accumulatedAwayScore += periodScore.awayScore;
        return <div key={index}>{`(${accumulatedHomeScore}:${accumulatedAwayScore})`}</div>;
      }
      return <div key={index}>{`(${periodScore.homeScore}:${periodScore.awayScore})`}</div>;
    });
  }

  const setMatchTrackerTab = (tab) => {
    console.log(tab);
  };

  if (match === undefined || matchDetail === undefined) return <div />;

  return (
    <>
      <div
        className="matchdetail_header"
        style={{
          backgroundImage: `url(${AWS_BUCKET_URL}/Icons/122.png)`,
          backgroundSize: 'cover',
          height: Math.min(window.innerWidth / 2.2, 170)
        }}>
        <Row>
          <div className="headerMatchInfo">
            <Row>
              <Col xs={10}>
                <div className="d-flex flex-row">
                  <GoBack
                    location={location.path}
                    selectSport={location.sport}
                    position={'detail'}
                  />
                  <div className="ml-2">
                    <div className="fsize-13">
                      {match.eventStatus > EnumEventStatus.NOTSTARTED ? (
                        <Badge pill bg="danger">
                          {t('components.matchcard.live')}
                        </Badge>
                      ) : (
                        <div className="fsize-10">
                          {gameDateToMatchDayFormatted(match.gameDate, getLanguageCode())}{' '}
                        </div>
                      )}
                    </div>
                    <div className="d-flex flex-row">
                      <div className="pt-1 mr-2 fsize-13">{match.league}</div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xs={2} className="stats">
                <div
                  onClick={() => {
                    matchtrackerCallback('standings');
                  }}>
                  <img width="35" height="25" src={`${AWS_BUCKET_URL}/Icons/94.png`} />
                </div>
              </Col>
            </Row>
            <div className="stats"></div>
          </div>
        </Row>

        <Row>
          <Col xs={4}>
            <div className="team1 ml-5">
              {getTeamNameHelper(locales, selectedLocale, matchDetail.home, t)}
            </div>
          </Col>
          <Col xs={4}>
            <div className="score">
              {match.eventStatus > EnumEventStatus.NOTSTARTED ? (
                getMatchTime(match, t)
              ) : (
                <div className="text-capitalize">{t(`matchStatus.${match.matchStatusId}`)}</div>
              )}
            </div>
            <div className="headerMatchHalfTimeResult">
              <PeriodScoreView />
            </div>
          </Col>
          <Col xs={4}>
            <div className="team1 mr-4">
              {getTeamNameHelper(locales, selectedLocale, matchDetail.away, t)}
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="team1 mt-2">{matchDetail.home.homeScore}</div>
          </Col>
          <Col></Col>
          <Col>
            <div className="team1  mt-2">{matchDetail.away.awayScore}</div>
          </Col>
        </Row>
      </div>

      <div className="match-tracker">
        <MatchTracker
          matchId={match.providerId.split(':')[2]}
          sportId={match.sport}
          language={getLanguageCode().split('-')[0]}
          selectedTabCallbackFunc={setMatchtrackerCallback}
        />
      </div>

      <div className="scrollmenuFullWidth blackBgColor">
        {availableMarketGroups &&
          availableMarketGroups.map((market) => {
            return (
              <div
                key={market}
                className={`sport-item ${selectedMarket === market ? ' green-underline' : ''}`}
                onClick={() => setSelectedMarket(market)}>
                {t(`betGroup.${market}`)}
              </div>
            );
          })}
      </div>

      <div className="container bg-gray-400 pe-1 ps-1">
        {availableMarketGroups && getMarkets(selectedMarket)}
      </div>
    </>
  );
}
export default MatchDetail;
